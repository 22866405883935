import React from 'react';

import { ReactComponent as OperationFacturation } from '../../assets/svg/Header/OperationFacturation.svg'
import { ReactComponent as OperationShopping } from '../../assets/svg/Header/OperationShopping.svg'
import { ReactComponent as OperationJuice } from '../../assets/svg/Header/OperationJuice.svg'
import { ReactComponent as OperationPartnership } from '../../assets/svg/Header/OperationPartnership.svg'
import { ReactComponent as OperationLogisticList } from '../../assets/svg/Header/OperationLogisticList.svg'
import { ReactComponent as OperationPicking } from '../../assets/svg/Header/OperationPicking.svg'
import { ReactComponent as OperationStock } from '../../assets/svg/Header/OperationStock.svg'
import { ReactComponent as OperationCheckList } from '../../assets/svg/Header/OperationCheckList.svg'
import { ReactComponent as OperationStatistic } from '../../assets/svg/Header/OperationStatistic.svg'
import { ReactComponent as OperationShipmentPartners } from '../../assets/svg/Header/OperationShipmentPartners.svg'
import { ReactComponent as OperationSPO } from '../../assets/svg/Header/OperationSPO.svg'
import { ReactComponent as OperationBuyer } from '../../assets/svg/Header/OperationBuyer.svg'
import { ReactComponent as OperationLowAnalyzer } from '../../assets/svg/Header/OperationLowAnalyzer.svg'
import { ReactComponent as OperationOrderHistory } from '../../assets/svg/Header/OperationOrderHistory.svg'

const useMapOperationIcon = (operation) => {
  switch (operation) {
    case 'OperationFacturation':
      return <OperationFacturation width={'30px'} height={'30px'} />
    case 'OperationJuice':
      return <OperationJuice width={'30px'} height={'30px'}/>
    case 'OperationShopping':
      return <OperationShopping width={'30px'} height={'30px'} />
    case 'OperationBuyer':
      return <OperationBuyer width={'30px'} height={'30px'} />
    case 'OperationLowAnalyzer':
      return <OperationLowAnalyzer width={'30px'} height={'30px'} />
    case 'OperationShipmentPartners':
      return <OperationShipmentPartners width={'30px'} height={'30px'} />
    case 'OperationPartnership':
      return <OperationPartnership width={'30px'} height={'30px'} />
    case 'OperationOrderHistory':
      return <OperationOrderHistory width={'30px'} height={'30px'} />
    case 'OperationSPO':
      return <OperationSPO width={'30px'} height={'30px'} />
    case 'OperationLogisticList':
      return <OperationLogisticList width={'30px'} height={'30px'} />
    case 'OperationPicking':
      return <OperationPicking width={'30px'} height={'30px'} />
    case 'OperationStock':
      return <OperationStock width={'30px'} height={'30px'} />
    case 'OperationCheckList':
      return <OperationCheckList width={'30px'} height={'30px'} />
    case 'OperationStatistic':
      return <OperationStatistic width={'30px'} height={'30px'} />
    default:
      return <></>
  }
}

export default useMapOperationIcon