import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  save: false,
}

export const changeItemsSlice = createSlice({
  name: 'changeItems',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload
    },
    changeSave: (state, action) => {
      state.save = action.payload
    },
    changeItem: (state, action) => {
      const item = action.payload
      const index = state.items.findIndex((elem) => elem._id === item._id)
      if (index !== -1) {
        state.items[index] = item
      }
    },
    changeItems: (state, action) => {
      const selectedItems = action.payload
      selectedItems.forEach((item) => {
        const index = state.items.findIndex((elem) => elem._id === item._id)
        if (index !== -1) {
          state.items[index] = item
        }
      })
    },
    // Новый экшен для изменения статуса элементов
    changeItemsStatus: (state, action) => {
      const { selectedItems, status } = action.payload
      selectedItems.forEach((itemId) => {
        const index = state.items.findIndex((elem) => elem._id === itemId)
        if (index !== -1) {
          state.items[index].status = status
        }
      })
    },
    checkItemStatus: (state, action) => {
      const { itemId, status } = action.payload
      const item = state.items.find((elem) => elem._id === itemId)
      if (item) {
        item.statusChecked = item.status === status
      }
    },
    getItemStatus: (state, action) => {
      const itemSku = action.payload
      const item = state.items.find((elem) => elem.sku === itemSku)
      return item ? item.status : null
    },
  },
})

export const { changeSave, changeItem, setItems, changeItems, changeItemsStatus, checkItemStatus } = changeItemsSlice.actions
export default changeItemsSlice.reducer
