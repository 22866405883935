import React, { useEffect, useMemo, useState } from 'react'
import './OrderInfo.scss'
import { TextField, Select, MenuItem, Modal, Box, Button, Tooltip, Typography } from '@mui/material'
// import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { mainApi } from '../../../../../store/services/mainApi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import calendar from '../../../../../assets/png/Dashboard/calendar.png'
import { DateCalendar, DateTimePicker } from '@mui/x-date-pickers'
// import { TimeClock } from '@mui/x-date-pickers/TimeClock'

import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { split } from 'lodash'
import { WhatsApp } from '@mui/icons-material'
import { logAction } from '../../../../../utils/logsAction/logService'
import { ReactComponent as ChatLink } from '../../../../../assets/svg/Dashboard/chatLink.svg'
import { changeSave } from '../../../../../store/slices/changeItems'

export default function OrderInfo({ editOrder, order, setEditOrder }) {
  const [payOrders] = mainApi.usePayOrdersMutation()
  const [changeDeliveryDate, { isLoading }] = mainApi.useChangeDeliveryDateMutation()
  const [changeDescriptionOrder] = mainApi.useChangeDescriptionMutation()
  const [changeInnerDescriptionOrder] = mainApi.useChangeInnerDescriptionMutation()
  const [changeDeliveryTime] = mainApi.useChangeOrderDeliveryTimeMutation()
  const [changePaymentType] = mainApi.useChangeOrderPaymentTypeMutation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const usr = JSON.parse(localStorage.getItem('acc'))

  const save = useSelector((state) => state.changeItems.save)

  const { items, deliveryPrice, paidValue, deliveryDate, deliveryTime } = order

  const [currentPaymentType, setCurrentPaymentType] = useState(order?.paymentType || '')
  const [currentDeliveryTime, setCurrentDeliveryTime] = useState(deliveryTime && deliveryTime.includes('PLT') ? 'PLT' : 'PLM')

  //date picker
  const [timePickerModal, setTimePickerModal] = useState(false)

  const [changableDate, setChangableDate] = useState(dayjs())
  const [changableTime, setChangableTime] = useState(dayjs())

  // const [paid, setPaid] = useState('no')
  const [paidChangebleValue, setPaidChangebleValue] = useState(+paidValue)
  const [deliveryDateChangebleValue, setDeliveryDateChangebleValue] = useState(deliveryDate)

  const [changeDescription, setChangeDescription] = useState(order.description)

  const [changeInnerDescription, setChangeInnerDescription] = useState(order?.innerDescription || '')

  const creationDate = new Date(order.date).toLocaleString().split(',')[0]
  const creationTime = new Date(order.date).toLocaleString().split(',')[1].slice(0, -3)
  // const deliveryDate = new Date(order.deliveryDate).toLocaleString().split(',')[0]

  useEffect(() => {
    if (order) {
      setChangeDescription(order?.description || '')
    }
  }, [order])

  const total = useMemo(() => {
    if (items) {
      return items
        .map((item) => (item.marketPriceCP ? parseFloat(item?.marketPriceCP * (item?.quantity || 1)) : 0))
        .reduce((acc, curr) => acc + curr, 0)
    }
    return 0
  }, [items, deliveryPrice])

  const paid = useMemo(() => {
    if (paidChangebleValue == '0') {
      return 'no'
    } else if (paidChangebleValue >= total) {
      return 'yes'
    } else {
      return 'parcial'
    }
  })

  //.reduce((acc, curr) => acc + curr, 0)

  const profit = useMemo(() => {
    if (order.items) {
      return order.items
        .map((item) =>
          order.items.marketPriceCP != ''
            ? parseFloat(item.marketPriceCP * (item.quantity || 0)) - parseFloat(item.ourPrice * (item.quantity || 0))
            : 0,
        )
        .reduce((acc, curr) => acc + curr, 0)
    }
    return 0
  })

  const deliveryDateNormalFormat = useMemo(() => {
    if (typeof deliveryDate === 'string') {
      // const originalDate = new Date(deliveryDate)

      if (deliveryDate.includes('T')) {
        return deliveryDate.split('T')[0].replaceAll('-', '.') + ' ' + deliveryDate.split('.')[0].split('T')[1]
      }
      // const formattedDate = `${originalDate.getDate().toString().padStart(2, '0')}.${(originalDate.getMonth() + 1)
      //   .toString()
      //   .padStart(2, '0')}.${originalDate.getFullYear()} ${(originalDate.getHours() - 3).toString().padStart(2, '0')}:${originalDate
      //   .getMinutes()
      //   .toString()
      //   .padStart(2, '0')}`

      setChangableDate(dayjs(deliveryDate.split('T')[0]))

      // return formattedDate
    } else if (typeof deliveryDate === 'number') {
      return new Date(deliveryDate).toDateString()
    } else {
      return 'no date'
    }
  }, [deliveryDate])

  const debtValue = useMemo(() => {
    return (paidChangebleValue ? total + (+order?.deliveryPrice || 0) - paidChangebleValue : total).toFixed(2)
  }, [paidChangebleValue, total])

  const handleChangeDescription = async () => {
    changeDescriptionOrder({
      data: {
        _id: order._id,
        description: changeDescription,
      },
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['Order']))
        alert('Description successfully changed')
      })
      .catch((err) => {
        alert(err.data.message)
      })

    logAction('Changed description', {
      orderId: order._id,
      innerDescription: changeDescription,
      oldInnerDescription: order?.description,
      user: {
        ...usr,
        actions: {},
      },
    })
  }

  const handleChangeInnerDescription = async () => {
    changeInnerDescriptionOrder({
      data: {
        _id: order._id,
        innerDescription: changeInnerDescription,
      },
    })
      .unwrap()
      .then(() => {
        dispatch(mainApi.util.invalidateTags(['Order']))
        alert('Inner description successfully changed')
      })
      .catch((err) => {
        alert(err.data.message)
      })

    logAction('Changed inner description', {
      orderId: order._id,
      innerDescription: changeInnerDescription,
      oldInnerDescription: order?.innerDescription,
      user: {
        ...usr,
        actions: {},
      },
    })
  }

  const handleChangePaid = (e) => {
    console.log(e)
    if (e == 'yes') {
      setPaidChangebleValue(total)
    } else if (e == 'parcial') {
      setPaidChangebleValue(total / 2)
    } else {
      setPaidChangebleValue(0)
    }
  }

  useEffect(() => {
    if (save) {
      payOrders({
        data: {
          _id: order._id,
          value: +paidChangebleValue,
        },
      })
      dispatch(mainApi.util.invalidateTags(['Order']))

      if (paidChangebleValue) {
        logAction('Paid order', {
          orderId: order._id,

          user: {
            ...usr,
            actions: {},
          },
        })
      }
    }
  }, [save])

  const handleChangeDeliveryDate = async () => {
    if (changableDate.$H > 20 || changableDate.$H < 10) {
      alert('Delivery date must be between 10:00 - 20:00')
    } else {
      const date = new Date(changableDate.$d)
      const dateString = date.toLocaleString()
      console.log(dateString)
      await changeDeliveryDate({
        data: {
          _id: order._id,
          deliveryDate: dateString.replaceAll('/', '.'),
        },
      })
        .unwrap()
        .then(() => {
          setTimePickerModal(false)
          dispatch(mainApi.util.invalidateTags(['Order']))
        })

      logAction('Changed delivery date', {
        orderId: order._id,
        deliveryDate: dateString.replaceAll('/', '.'),
        oldDeliveryDate: order?.deliveryDate,
        user: {
          ...usr,
          actions: {},
        },
      })
    }
  }

  const goToWhatsapp = () => {
    if (order?.msg) {
      window.open(`https://wa.me/${order?.msg ? order?.msg?._data?.from.split('@')[0] : ''}`, '_blank')
    } else if (order?.phone) {
      window.open(`https://wa.me/${order?.phone ? order?.phone : ''}`, '_blank')
    } else if (order?.userAcc?.phone) {
      window.open(`https://wa.me/${order?.userAcc?.phone ? order?.userAcc?.phone : ''}`, '_blank')
    } else {
      window.open(`https://wa.me/`, '_blank')
    }
  }

  const totalWeight = useMemo(() => {
    const unitConversions = {
      kg: 1000, // 1 kg = 1000 g
      gr: 1, // 1 g = 1 g
      lt: 1000, // 1 lt = 1000 g
      ml: 1, // 1 ml = 1 g
    }

    return order.items.reduce((total, product) => {
      const { currentUnit, weightValue, weightUnits, quantityOrder } = product
      const weightInGrams = weightValue * (unitConversions[weightUnits] || 1)
      const totalProductWeight = weightInGrams * quantityOrder

      return total + totalProductWeight
    }, 0)
  }, [order.items])

  const setChangePaymentType = (order, value) => {
    try {
      changePaymentType({
        ...order,
        paymentType: value,
      })
        .unwrap()
        .then((res) => {
          if (!res.acknowledged) {
            alert('erorr')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  const setChangeDeliveryTime = (order, value) => {
    try {
      changeDeliveryTime({
        ...order,
        deliveryTime: value == 'PLT' ? 'PLT 🌙' : 'PLM ☀️',
      })
        .unwrap()
        .then((res) => {
          if (!res.acknowledged) {
            alert('erorr')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }
  
  const [startEdittingOrder] = mainApi.useStartEditingOrderMutation()
  
  const [finishEdittingOrder] = mainApi.useFinishEditingOrderMutation()

  const changeEditting = () => {
    if (!editOrder) {
      startEdittingOrder({ orderId: order._id, userId: usr._id })
        .unwrap()
        .then(() => {
          setEditOrder(true)
        })
        .catch((error) => {
          console.log(error)
          // setInfo(error.data.user?.name + ' ' + error.data.user?.phone + ' ' + error.data.user?.email)
          // setOpen(true)
        })
    } else {
      finishEdittingOrder({ orderId: order._id, userId: usr._id })
        .unwrap()
        .then(() => {
          setEditOrder(false)
        })
        .catch((error) => {
          alert(error)
        })
    }
  }

  const findLinks = (text) => {
    const regex = /((http|https):\/\/[^\s]+)/g;
    const links = text.match(regex) || [];
    return links;
  };
  const [innerCommentLinks, setInnerCommentLinks] = useState([])
  useEffect(() => {
    setInnerCommentLinks(findLinks(order?.innerDescription ? order.innerDescription : ''))
  }, [order])

  const [showModal, setShowModal] = useState(false)


  const handleCloseModal = () => {
    setShowModal(false)
  }
  const handleOpenModal = () => {
    dispatch(changeSave(true))
    setShowModal(true)
  }

  const [changeItems, { isLoading: isLoadingChangeItems }] = mainApi.useChangeItemsInOrderMutation()

  const [updatingOrderModal, setUpdatingOrderModal] = useState(false)

  useEffect(() => {
    if (!isLoadingChangeItems) {
      setUpdatingOrderModal(false)
    } else {
      setUpdatingOrderModal(true)
    }
  }, [isLoadingChangeItems])

  const calculateDifferences = (oldItems, newItems) => {
    const oldMap = new Map(oldItems.map((item) => [item.id, item]))
    const newMap = new Map(newItems.map((item) => [item.id, item]))

    const added = newItems.filter((item) => !oldMap.has(item.id))
    const removed = oldItems.filter((item) => !newMap.has(item.id))

    const modified = newItems
      .filter((item) => {
        const oldItem = oldMap.get(item.id)
        return oldItem && JSON.stringify(oldItem) !== JSON.stringify(item)
      })
      .map((item) => {
        const oldItem = oldMap.get(item.id)
        const changes = {}

        for (const key in item) {
          if (item[key] !== oldItem[key]) {
            changes[key] = { old: oldItem[key], new: item[key] }
          }
        }

        return { id: item._id, sku: item.sku, changes }
      })

    return { added, removed, modified }
  }

  const logItemChanges = async (order, newItems, usr) => {
    const { added, removed, modified } = calculateDifferences(order.items, newItems)

    const changeDetails = {
      orderId: order._id,
      changes: {
        added: added.map((item) => ({ id: item._id, name: item.name })),
        removed: removed.map((item) => ({ id: item._id, name: item.name })),
        modified,
      },
      user: {
        ...usr,
        actions: {},
      },
    }

    await logAction('Change items', changeDetails)
  }

  
  const perviousPaidValue = order?.paidValue ? order.paidValue : 0
  
  const newItems = useSelector((state) => state.changeItems.items)

  const agree = async () => {
    if (perviousPaidValue !== order.paidValue) {
      payOrders({
        data: {
          value: order.paidValue,
          _id: order._id,
        },
      })
    }

    await changeItems({
      elem: {
        ...order,
        items: [...newItems],
      },
    })
      .unwrap()
      .then(() => {
        setShowModal(false)

        dispatch(changeSave(false))
        dispatch(mainApi.util.invalidateTags(['Order']))

        finishEdittingOrder({ orderId: order._id, userId: usr._id })
          .unwrap()
          .then(() => {
            setEditOrder(false)
            dispatch(mainApi.util.invalidateTags(['Order']))
          })
          .catch((error) => {
            console.error('Error:', error)
          })

        // TYT 9I JOPY m9JI

        const info = {
          data: {
            items: [...newItems],
            orderID: order._id,
            order: order,
          },
        }

        fetch('https://tpomobi.shop/mondayChangeItemZumos', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(info),
        }).catch((error) => {
          console.error('Error:', error)
        })

        console.log('JPY')
        fetch('https://tpomobi.shop/sendOrderChangeInfo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: { order: { ...order, items: [...newItems] } } }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data)
          })
          .catch((error) => {
            console.error('Error:', error)
          })
      })

    logItemChanges(order, newItems, usr)
  }

  const cancel = () => {
    setShowModal(false)
    dispatch(changeSave(false))
  }

  return (
    <div className='orderInfo'>
      <div className='orderInfo__box'>
        <div className='orderInfo__box__item'>
          <p>
            ORDER: <span>{order.idTucomida ? order.idTucomida : order?._id ? order?._id.slice(-6) : ''}</span>
          </p>
          <button 
            onClick={() => {changeEditting()}}
            style={{ 
              borderRadius: '6px',
              background: '#176318',
              border: 'none',
              padding: '5px 10px',
              color: 'white',
              width: '58px',
            }}
          >
            {editOrder ? 'Back' : 'Edit'}
          </button>
          {editOrder && 
          <button 
            onClick={handleOpenModal}
            style={{ 
              borderRadius: '6px',
              background: '#176318',
              border: 'none',
              padding: '5px 10px',
              color: 'white',
              width: '58px',
            }}
          >
            Save
          </button>}
        </div>

        <div className='orderInfo__box__item'>
          <p>PAYMENT TYPE: </p>
          <span>
            <Select
              labelId='demo-simple-checkbox-label'
              id='demo-simple-checkbox'
              color='success'
              value={currentPaymentType}
              onChange={(e) => {
                setCurrentPaymentType(e.target.value)
                setChangePaymentType(order, e.target.value)
              }}
            >
              <MenuItem value={'Tarjeta'}>Tarjeta 💳</MenuItem>
              <MenuItem value={'Transferencia inmediata'}>Transferencia inmediata</MenuItem>
              <MenuItem value={'Efectivo'}>Efectivo</MenuItem>
              <MenuItem value={'Factura invoice'}>Factura invoice</MenuItem>
            </Select>
          </span>
        </div>

        <div className='orderInfo__box__item'>
          <div
            style={{
              width: '100%',
              display: 'flex',
              gap: '10px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>
              TOTAL: <span>{deliveryPrice ? (total + deliveryPrice).toFixed(2) : total.toFixed(2)}€</span>
            </p>
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              Paid:
              {editOrder ? (
                <TextField
                  type='number'
                  sx={{
                    width: '50px',
                    '& .MuiInputBase-input': {
                      textAlign: 'right',
                      padding: '2px',
                      fontSize: '14px',
                    },
                  }}
                  onChange={(e) => setPaidChangebleValue(e.target.value)}
                  value={paidChangebleValue}
                />
              ) : (
                <span>{paidChangebleValue ? parseFloat(paidChangebleValue)?.toFixed(2) : 0}€</span>
              )}
              <Select disabled={!editOrder} variant='standard' value={paid} onChange={(e) => handleChangePaid(e.target.value)}>
                <MenuItem value='yes'>Yes</MenuItem>
                <MenuItem value='no'>No</MenuItem>
                <MenuItem value='parcial'>Parcial</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className='orderInfo__box__item'>
          <div
            style={{
              alignItems: 'center',
              width: '100%',
              display: 'flex',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            {localStorage.getItem('name') != 'VPF' && (
              <p>
                PROFIT:{' '}
                <span>
                  {profit.toFixed(2)}€ ({((profit / total) * 100).toFixed(2)}%)
                </span>
              </p>
            )}
            <p>
              DEBT: <span style={debtValue > 0 ? { color: 'red' } : { color: 'green' }}>{debtValue}€</span>
            </p>
          </div>
        </div>
      </div>
      <div className='orderInfo__box'>
        <div className='orderInfo__box__item'>
          <p>
            CREATION:{' '}
            <span>
              {creationDate} {creationTime}
            </span>
          </p>
        </div>
        <div className='orderInfo__box__item'>
          <p>
            DELIVERY: <span>{deliveryDate ? deliveryDate.split(',')[0] : ''} </span>
            <span>
              <Select
                labelId='demo-simple-checkbox-label'
                id='demo-simple-checkbox'
                color='success'
                value={currentDeliveryTime}
                onChange={(e) => {
                  setCurrentDeliveryTime(e.target.value)
                  setChangeDeliveryTime(order, e.target.value)
                }}
              >
                <MenuItem value={'PLM'}>PLM ☀️</MenuItem>
                <MenuItem value={'PLT'}>PLT 🌙</MenuItem>
              </Select>
            </span>
            {/*<span>{deliveryDate && deliveryDate.includes(',') ? (deliveryDate.split(',')[1].slice(0, -6) > 12 ? '🌛' : '🌞') : ''}</span>*/}
          </p>
          <img src={calendar} style={{ cursor: 'pointer' }} alt='calendar' width={22} height={24} onClick={() => setTimePickerModal(true)} />

          <Modal
            open={timePickerModal}
            onClose={() => setTimePickerModal(false)}
            sx={{
              '& .MuiBackdrop-root': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '35%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '30%',
                // height: 100,
                // backgroundColor: 'white',
              }}
            >
              <DateTimePicker
                value={changableDate}
                onChange={(value) => {
                  console.log(value)
                  setChangableDate(value)
                }}
                sx={{
                  backgroundColor: 'white',
                  zIndex: '100',

                  // '&.MuiFormControl-root': {
                  //   position: 'absolute',
                  //   top: '35%',
                  //   left: '30%',
                  // },
                }}
                format='DD.MM.YYYY HH:mm'
                // minDate={dayjs()}
                minTime={dayjs().set('hour', 10).set('minute', 0).set('second', 0)}
                maxTime={dayjs().set('hour', 19).set('minute', 59).set('second', 59)}
                // disable agree when click on PM or AM
              />
              <Button disabled={isLoading} variant='contained' sx={{ marginLeft: '10px' }} color='success' onClick={() => handleChangeDeliveryDate()}>
                Change
              </Button>
            </Box>
          </Modal>
        </div>
        <div className='orderInfo__box__item'>
          <p>
            ADDRESS:{' '}
            {order.msg ? (
              <span>{order.description ? order.description.split('Delivery address: ')[1] : 'No Address'}</span>
            ) : (
              <span>{order?.userAcc?.comArdress || order?.userAcc?.billAddress?.address || order?.address || 'No Address'}</span>
            )}
          </p>
        </div>

        <div className='orderInfo__box__item'>
          <p>
            PHONE:{' '}
            {order.msg ? (
              <span>{order.description ? order.msg.from.split('@')[0] : 'No Phone'}</span>
            ) : (
              <span>{order?.userAcc?.phone || order?.phone || 'No Phone'}</span>
            )}
          </p>
        </div>

        <div className='orderInfo__box__item'>
          <p>
            TOTAL ITEMS: <span>{items ? items.length : 0}</span>
          </p>
        </div>
        <div className='orderInfo__box__item'>
          <p>
            APPROXIMATE WEIGHT: <span>{totalWeight ? (totalWeight / 1000).toFixed(2) + ' kg' : 0}</span>
          </p>
        </div>
      </div>
      <div className='orderInfo__box'>
        <div style={{ position: 'relative', flexDirection: 'column', alignItems: 'flex-start' }} className='orderInfo__box__item'>
          <div style={{ display: 'flex', width: '100%' }}>
            <p style={{ marginBottom: '10px' }}>COMMENT:</p>
          </div>

          {/* <div style={{ display: 'flex', width: '100%' }}>

          </div> */}
          <div
            style={{
              overflowY: 'scroll',
              minHeight: '50px',
              // minWidth: '300px',
              width: '100%',
              // maxWidth: '150px',
              // maxHeight: '50px',
              borderRadius: '7px',
              border: '0.2px solid #000',
              background: 'linear-gradient(180deg, #FFF 99.99%, rgba(23, 99, 24, 0.00) 100%)',
              boxShadow: '0px 4px 4px 0px rgba(23, 99, 24, 0.25)',
              position: 'relative',
              marginBottom: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: '5px', marginBottom: '5px'}}>
              {innerCommentLinks?.map((link, index) => (
                <button
                  key={index}
                  onClick={() => window.open(link, '_blank')}
                  style={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px',
                    border: '0px solid #176318',
                    background: '#E5EDE5',
                    color: '#000',

                    '&:hover': {
                      opacity: '0.8',
                      backgroundColor: '#E5EDE5',
                    },
                  }}
                >
                  <ChatLink width={25} />
                </button>
              ))}
            </div>

            {editOrder ? (
              <textarea
                value={changeInnerDescription}
                onChange={(event) => {
                  setChangeInnerDescription(event.target.value)
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',

                  outline: 'none',
                  padding: '5px',
                }}
              />
            ) : (
              <span>{order.innerDescription ? order.innerDescription : 'inner comment'}</span>
            )}
            <Button
              sx={{
                position: 'absolute',
                width: '80px',
                height: '30px',
                bottom: '0px',
                right: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '6px',
                border: '0px solid #176318',
                background: '#E5EDE5',
                color: '#000',

                '&:hover': {
                  opacity: '0.8',
                  backgroundColor: '#E5EDE5',
                },
              }}
              disabled={!editOrder}
              onClick={() => {
                handleChangeInnerDescription()
              }}
            >
              Save
            </Button>
          </div>
          <div
            style={{
              overflowY: 'scroll',
              minHeight: '85px',
              // minWidth: '300px',
              // maxWidth: '300px',
              
              width: '100%',
              maxHeight: '85px',
              borderRadius: '7px',
              border: '0.2px solid #000',
              background: 'linear-gradient(180deg, #FFF 99.99%, rgba(23, 99, 24, 0.00) 100%)',
              boxShadow: '0px 4px 4px 0px rgba(23, 99, 24, 0.25)',
            }}
          >
            {editOrder ? (
              <textarea
                value={changeDescription}
                onChange={(event) => {
                  setChangeDescription(event.target.value)
                }}
                style={{
                  width: '100%',
                  height: '100%',
                  border: 'none',

                  outline: 'none',
                  padding: '5px',
                }}
              />
            ) : (
              <span>{order.description ? order.description : 'comment'}</span>
            )}
            {order.msg && (
              <Tooltip title={order?.msg?._data?.from.split('@')[0]} placement='top' arrow>
                <Button
                  sx={{
                    position: 'absolute',
                    width: '50px',
                    height: '30px',
                    bottom: '-10px',
                    right: '90px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px',
                    border: '0px solid #176318',
                    background: '#E5EDE5',
                    color: '#000',
                    '&:hover': {
                      opacity: '0.8',
                      backgroundColor: '#E5EDE5',
                    },
                  }}
                  onClick={() => {
                    goToWhatsapp()
                  }}
                >
                  <WhatsApp sx={{ marginRight: '5px', color: '#075e54' }} />
                </Button>
              </Tooltip>
            )}
            {order?.userAcc?.phone || order?.phone ? (
              <Tooltip title={order?.userAcc?.phone || order?.phone} placement='top' arrow>
                <Button
                  sx={{
                    position: 'absolute',
                    width: '50px',
                    height: '30px',
                    bottom: '-10px',
                    right: '90px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '6px',
                    border: '0px solid #176318',
                    background: '#E5EDE5',
                    color: '#000',
                    '&:hover': {
                      opacity: '0.8',
                      backgroundColor: '#E5EDE5',
                    },
                  }}
                  tooltip='Send to whatsapp'
                  onClick={() => {
                    goToWhatsapp()
                  }}
                >
                  <WhatsApp sx={{ marginRight: '5px', color: '#075e54' }} />
                </Button>
              </Tooltip>
            ) : null}
            <Button
              sx={{
                position: 'absolute',
                width: '80px',
                height: '30px',
                bottom: '-10px',
                right: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '6px',
                border: '0px solid #176318',
                background: '#E5EDE5',
                color: '#000',

                '&:hover': {
                  opacity: '0.8',
                  backgroundColor: '#E5EDE5',
                },
              }}
              disabled={!editOrder}
              onClick={() => {
                handleChangeDescription()
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',

            alignItems: 'center',
          }}
        >
          <Typography sx={{ mb: 2 }} id='modal-modal-title' variant='h6' component='h2'>
            Are you sure?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button onClick={agree} variant='contained' color='success'>
              Agree
            </Button>
            <Button onClick={cancel} variant='contained' color='error'>
              Disagree
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
