import React, { useEffect, useState, useMemo } from 'react'
import '../../../Orders/OrdersChange/CreateProduct/CreateProduct.scss'
import { Box, CircularProgress, Container, TextField, FormControl, Select, MenuItem, Button } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch, useSelector } from 'react-redux'

export default function CreateProductEmpt({ setShowModal }) {
  const [products, setProducts] = useState([])
  const dispatch = useDispatch()

  const newItems = useSelector((state) => state.changeItems.items)

  const { data: productsData, isLoading } = mainApi.useFindAllWordsQuery()
  const [createProduct, { isLoading: isLoadingProduct, isSuccess: isSuccessProduct }] = mainApi.useCreateProductMutation()

  useEffect(() => {
    if (productsData) {
      setProducts(productsData)
    }
  }, [productsData])

  //ficha

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  //thick for selection
  // const splitAndMapValues = (value) => {
  //   if (value.includes(',')) {
  //     return value.split(',').map((c) => ({ label: c.trim(), value: c.trim() }))
  //   }
  //   return [{ label: value, value: value }]
  // }

  //CatalogInfo
  const [img, setImg] = useState('')
  const [name, setName] = useState('')
  const [proveedor, setProveedor] = useState('')
  const [sku, setSku] = useState('')
  const [proveedorSku, setProveedorSku] = useState('')
  const [barcode, setBarcode] = useState('')

  //CatalogChangeBox
  const [category, setCategory] = useState('')
  const [brand, setBrand] = useState('')
  const [businessType, setBusinessType] = useState('')
  const [provider, setProvider] = useState('')
  const [description, setDescription] = useState('')
  const [unidad, setUnidad] = useState('')
  const [weight, setWeight] = useState('')
  const [weightUnits, setWeightUnits] = useState('')
  const [length, setLength] = useState('')
  const [width, setWidth] = useState('')
  const [height, setHeight] = useState('')
  const [minQuantity, setMinQuantity] = useState('')
  const [place, setPlace] = useState('')
  const [shelf, setShelf] = useState('')
  const [stand, setStand] = useState('')
  const [quantityStep, setQuantityStep] = useState('')
  const [decimal, setDecimal] = useState(false)
  const [active, setActive] = useState(false)
  const [stock, setStock] = useState('')
  const [days, setDays] = useState('')
  const [dias, setDias] = useState('')
  const [prId, setPrId] = useState('')

  //price

  const [discount, setDiscount] = useState(0)
  const [marketPrice, setMarketPrice] = useState(0)
  const [ourPrice, setOurPrice] = useState(0)
  const [IVA, setIVA] = useState(0)

  //values for multiSelect

  // const uniqueCategories = useMemo(() => {
  //   if (products) {
  //     let categoryArray = products.map((product) => product.category)
  //     categoryArray = categoryArray.flatMap((category) => {
  //       if (category === null || category === undefined || category === '') {
  //         return []
  //       }
  //       if (category.includes(',')) {
  //         return category.split(',').map((c) => c.trim())
  //       }
  //       return category
  //     })
  //     const uniqueSet = new Set(categoryArray)
  //     const uniqueCategoriesArray = Array.from(uniqueSet)
  //     uniqueCategoriesArray.sort()
  //     return uniqueCategoriesArray.map((category) => ({
  //       label: category,
  //       value: category,
  //     }))
  //   }
  // }, [products])

  // const uniqueBrands = useMemo(() => {
  //   if (products) {
  //     let brandArray = products.map((product) => product.brand)
  //     brandArray = brandArray.flatMap((brand) => {
  //       if (brand === null || brand === undefined || brand === '') {
  //         return []
  //       }
  //       if (brand.includes(',')) {
  //         return brand.split(',').map((c) => c.trim())
  //       }
  //       return brand
  //     })
  //     const uniqueSet = new Set(brandArray)
  //     const uniqueBrandsArray = Array.from(uniqueSet)
  //     return uniqueBrandsArray.map((brand) => ({
  //       label: brand,
  //       value: brand,
  //     }))
  //   }
  // }, [products])

  // const uniqueBusinessTypes = useMemo(() => {
  //   if (products) {
  //     let businessTypeArray = products.map((product) => product.businessType)
  //     businessTypeArray = businessTypeArray.flatMap((businessType) => {
  //       if (businessType === null || businessType === undefined || businessType === '') {
  //         return []
  //       }
  //       if (businessType.includes(',')) {
  //         return businessType.split(',').map((c) => c.trim())
  //       }
  //       return businessType
  //     })
  //     const uniqueSet = new Set(businessTypeArray)
  //     const uniqueBusinessTypesArray = Array.from(uniqueSet)
  //     return uniqueBusinessTypesArray.map((businessType) => ({
  //       label: businessType,
  //       value: businessType,
  //     }))
  //   }
  // }, [products])

  // const uniqueProviders = useMemo(() => {
  //   if (products) {
  //     let providerArray = products.map((product) => product.proveedor)
  //     providerArray = providerArray.flatMap((provider) => {
  //       if (provider === null || provider === undefined || provider === '') {
  //         return []
  //       }
  //       if (provider.includes(',')) {
  //         return provider.split(',').map((c) => c.trim())
  //       }
  //       return provider
  //     })

  //     const uniqueSet = new Set(providerArray)
  //     const uniqueProvidersArray = Array.from(uniqueSet)
  //     uniqueProvidersArray.sort()
  //     return uniqueProvidersArray.map((provider) => ({
  //       label: provider,
  //       value: provider,
  //     }))
  //   }
  // }, [products])

  // const uniqueUnidades = useMemo(() => {
  //   if (products) {
  //     let unidadArray = products.map((product) => product.unit)
  //     unidadArray = unidadArray.flatMap((unit) => {
  //       if (unit === null || unit === undefined || unit === '') {
  //         return []
  //       }
  //       if (unit.includes(',')) {
  //         return unit.split(',').map((c) => c.trim())
  //       }
  //       return unit
  //     })

  //     const uniqueSet = new Set(unidadArray)
  //     const uniqueUnidadesArray = Array.from(uniqueSet)
  //     uniqueUnidadesArray.sort()
  //     return uniqueUnidadesArray.map((unit) => ({
  //       label: unit,
  //       value: unit,
  //     }))
  //   }
  // }, [products])

  // const uniqueWeigthUnits = useMemo(() => {
  //   if (products) {
  //     const uniqueWeigthUnits = products.map((product) => product.weightUnits)
  //     uniqueWeigthUnits.flatMap((weightUnits) => {
  //       if (weightUnits === null || weightUnits === undefined || weightUnits === '') {
  //         return []
  //       }
  //       if (weightUnits.includes(',')) {
  //         return weightUnits.split(',').map((c) => c.trim())
  //       }
  //       return weightUnits
  //     })
  //     const uniqueSet = new Set(uniqueWeigthUnits)
  //     const uniqueWeigthUnitsArray = Array.from(uniqueSet)
  //     uniqueWeigthUnitsArray.sort()
  //     return uniqueWeigthUnitsArray.map((weightUnits) => ({
  //       label: weightUnits,
  //       value: weightUnits,
  //     }))
  //   }
  // }, [products])

  // handleSubmiForm

  const handleSubmiForm = async (e) => {
    e.preventDefault()
    if (!name && !proveedor) {
      alert('Name and provider are required')
    } else if (!marketPrice && !ourPrice && !IVA) {
      alert('Market price, our price and IVA are required')
    } else {
      const body = {
        // price
        marketPrice: +marketPrice,
        marketPriceDP: +marketPrice,
        marketPriceSDP: +marketPrice,
        marketPriceCP: +marketPrice,
        ourPrice: +ourPrice,
        ourPriceCP: +ourPrice,
        ourPriceSDP: +ourPrice,
        ourPriceDP: +ourPrice,
        IVA: +IVA,
        IVADP: +IVA,
        IVASDP: +IVA,
        IVACP: +IVA,
        MB: 0,
        DP: 0,
        SDP: 0,
        CP: 0,
        SPOPrice: discount ? +discount : null,
        // main info
        img: img ? img : null,
        name: name,
        proveedor: typeof provider === 'string' ? provider : null,
        nameProveedor: proveedor,
        sku: sku ? sku : null,
        skuProveedor: proveedorSku ? proveedorSku : null,
        barcode: barcode ? barcode : null,
        // additional info
        category: category ? category : null,
        brand: brand ? brand : null,
        businessType: businessType ? businessType : null,
        desc: description ? description : null,
        dimension: null,
        unit: unidad ? unidad : null,
        weightValue: weight ? +weight : null,
        weightUnits: weightUnits ? weightUnits : null,
        length: length ? length : null,
        width: width ? width : null,
        height: height ? height : null,
        minQt: minQuantity ? +minQuantity : null,
        place: place ? place : null,
        shelf: shelf ? shelf : null,
        stand: stand ? stand : null,
        quantityStep: quantityStep ? +quantityStep : 1,
        quantity: 0,
        decimal: decimal ? +decimal : null,
        enabled: active,
        stock: stock ? +stock : null,
        days: dias ? +dias : null,
        dias: dias ? +dias : null,
        id: products.length + 1,
        status: { _id: '64f7219dca9b73bd3db67950', name: 'New', desc: '', color: '#359970', openColorPicker: false, type: 'products' },
      }
      await createProduct({
        data: body,
      })
    }
  }

  useEffect(() => {
    if (isSuccessProduct) {
      setShowModal(false)
      dispatch(mainApi.util.invalidateTags(['Products']))
    }
  }, [isSuccessProduct])

  return (
    <Box
      className='createProduct'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        maxHeight: '70%',
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
        borderRadius: '10px',
      }}
    >
      {isLoading || loading ? (
        <CircularProgress />
      ) : (
        <FormControl component='form' fullWidth onSubmit={handleSubmiForm} className='createProduct__container'>
          <h2>Product</h2>
          {/* <div className='createProduct__item'>
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label='Image uri'
              size='small'
              variant='outlined'
              value={img}
              onChange={(e) => setImg(e.target.value)}
            />
          </div> */}
          <div className='createProduct__item'>
            <TextField
              error={!name}
              sx={{ mb: 2 }}
              fullWidth
              label='Name'
              size='small'
              variant='outlined'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='createProduct__item'>
            {/* <TextField
              error={!proveedor}
              sx={{ mb: 2 }}
              fullWidth
              label='Proveedor name'
              size='small'
              variant='outlined'
              value={proveedor}
              onChange={(e) => setProveedor(e.target.value)}
            /> */}
            {/* <Selection departmentNames={uniqueProviders} initialValue={[]} onchange={setProvider} /> */}
          </div>
          <div className='createProduct__item'>
            <TextField sx={{ mb: 2 }} fullWidth label='SKU' size='small' variant='outlined' value={sku} onChange={(e) => setSku(e.target.value)} />
          </div>
          {/*<div className='createProduct__item'>
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label='Proveedor Sku'
              size='small'
              variant='outlined'
              value={proveedorSku}
              onChange={(e) => setProveedorSku(e.target.value)}
            />
          </div>
          <div className='createProduct__item'>
            <TextField
              sx={{ mb: 2 }}
              fullWidth
              label='Barcode'
              size='small'
              variant='outlined'
              value={barcode}
              onChange={(e) => setBarcode(e.target.value)}
            />
          </div> */}
          {/* <h2>Additional info</h2>
          <div className='createProduct__item'>
            <p>Category</p>
            <Selection className='createProduct__selection' departmentNames={uniqueCategories} initialValue={[]} onchange={setCategory} />
          </div>
          <div className='createProduct__item'>
            <p>Brands</p>
            <Selection departmentNames={uniqueBrands} initialValue={[]} onchange={setBrand} />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Business Type</p>
            <Selection departmentNames={uniqueBusinessTypes} initialValue={[]} onchange={setBusinessType} />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Proveedors</p>
            <Selection departmentNames={uniqueProviders} initialValue={[]} onchange={setProvider} />
          </div>
          <div className='createProduct__item'>
            <p>Description</p>

            <TextField
              id='standard-basic'
              size='small'
              label='Description'
              variant='outlined'
              value={description}
              multiline
              onChange={(e) => setDescription(e.target.value)}
              sx={{ width: '51%' }}
            />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Unidad</p>
            <Selection departmentNames={uniqueUnidades} initialValue={[]} onchange={setUnidad} />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Weight</p>
            <TextField
              id='standard-basic'
              size='small'
              label='Weight'
              variant='outlined'
              type='number'
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              sx={{ width: '51%' }}
            />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Weight Units</p>
            <Selection departmentNames={uniqueWeigthUnits} initialValue={[]} onchange={setWeightUnits} />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Dimensions</p>
            <div>
              <TextField
                id='standard-basic'
                size='small'
                label='Length (cm)'
                type='number'
                variant='outlined'
                value={length}
                onChange={(e) => setLength(e.target.value)}
                sx={{ maxWidth: 115 }}
              />

              <TextField
                id='standard-basic'
                size='small'
                label='Width (cm)'
                type='number'
                variant='outlined'
                value={width}
                onChange={(e) => setWidth(e.target.value)}
                sx={{ maxWidth: 115 }}
              />

              <TextField
                id='standard-basic'
                size='small'
                label='Height (cm)'
                type='number'
                variant='outlined'
                value={height}
                onChange={(e) => setHeight(e.target.value)}
                sx={{ maxWidth: 115 }}
              />
            </div>
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Min. Quantity</p>

            <TextField
              id='standard-basic'
              size='small'
              label='Min. Quantity'
              type='number'
              variant='outlined'
              value={minQuantity}
              onChange={(e) => setMinQuantity(e.target.value)}
              sx={{ width: '51%' }}
            />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Stock Location</p>
            <div>
              <TextField
                id='standard-basic'
                size='small'
                label='Place'
                variant='outlined'
                value={place}
                onChange={(e) => setPlace(e.target.value)}
                sx={{ maxWidth: 115 }}
              />

              <TextField
                id='standard-basic'
                size='small'
                label='Shelf'
                variant='outlined'
                value={shelf}
                onChange={(e) => setShelf(e.target.value)}
                sx={{ maxWidth: 115 }}
              />

              <TextField
                id='standard-basic'
                size='small'
                label='Stand'
                variant='outlined'
                value={stand}
                onChange={(e) => setStand(e.target.value)}
                sx={{ maxWidth: 115 }}
              />
            </div>
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Quantity step</p>
            <TextField
              id='standard-basic'
              size='small'
              label='Quantity step'
              variant='outlined'
              type='number'
              value={quantityStep}
              onChange={(e) => setQuantityStep(e.target.value)}
              sx={{ width: '51%' }}
            />
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Decimal</p>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <Select value={decimal} onChange={(e) => setDecimal(e.target.value)}>
                <MenuItem value='false'>No</MenuItem>
                <MenuItem value='true'>Yes</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Active</p>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <Select value={active} onChange={(e) => setActive(e.target.value)}>
                <MenuItem value='false'>No</MenuItem>
                <MenuItem value='true'>Yes</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          {/* <div className='createProduct__item'>
            <p>Stock</p>

            <div>
              <TextField
                id='standard-basic'
                size='small'
                label='Stock'
                variant='outlined'
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                sx={{ maxWidth: 115 }}
              />

              <TextField
                id='standard-basic'
                size='small'
                label='Days'
                variant='outlined'
                value={days}
                onChange={(e) => setDays(e.target.value)}
                sx={{ maxWidth: 115 }}
              />

              <TextField
                id='standard-basic'
                size='small'
                label='Dias'
                variant='outlined'
                value={dias}
                onChange={(e) => setDias(e.target.value)}
                sx={{ maxWidth: 115 }}
              />
            </div>
          </div> */}
          <h2>Price</h2>
          <div className='createProduct__item'>
            <p>Price</p>
            <TextField
              onChange={(e) => setMarketPrice(e.target.value)}
              type='number'
              value={marketPrice}
              defaultValue={marketPrice}
              id='standard-basic'
              size='small'
              variant='outlined'
              sx={{ maxWidth: 115 }}
              error={!marketPrice}
            />
          </div>
          <div className='createProduct__item'>
            <p>Cogs(Entry price)</p>
            <TextField
              onChange={(e) => setOurPrice(e.target.value)}
              type='number'
              value={ourPrice}
              defaultValue={ourPrice}
              id='standard-basic'
              size='small'
              variant='outlined'
              sx={{ maxWidth: 115 }}
              error={!ourPrice}
            />
          </div>
          <div className='createProduct__item'>
            <p>IVA</p>
            {/* <TextField
              onChange={(e) => setIVA(e.target.value)}
              type='number'
              value={IVA}
              defaultValue={IVA}
              id='standard-basic'
              size='small'
              variant='outlined'
              sx={{ maxWidth: 115 }}
              error={!IVA}
            /> */}
            <Select value={IVA} onChange={(e) => setIVA(e.target.value)}>
              <MenuItem value={0}>0%</MenuItem>
              <MenuItem value={4}>4%</MenuItem>
              <MenuItem value={5}>5%</MenuItem>
              <MenuItem value={10}>10%</MenuItem>
              <MenuItem value={21}>21%</MenuItem>
            </Select>
          </div>
          {/* <div className='createProduct__item'>
            <p>Discount</p>
            <TextField
              onChange={(e) => setDiscount(e.target.value)}
              type='number'
              value={discount}
              defaultValue={discount}
              id='standard-basic'
              size='small'
              variant='outlined'
              sx={{ maxWidth: 115 }}
            />
          </div> */}
          {isLoadingProduct ? (
            <CircularProgress sx={{ margin: '0 auto' }} />
          ) : (
            <Button sx={{ width: '100%', mt: 2 }} color='success' variant='contained' type='submit'>
              Create
            </Button>
          )}
        </FormControl>
      )}
    </Box>
  )
}
