import {
    Box,
    CircularProgress,
    Container,
    TextField,
    Snackbar,
    FormControl,
    Button,
    Checkbox,
    ListItemText,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    useMediaQuery, Modal
} from '@mui/material'


import { ReactComponent as Like } from '../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../assets/svg/Congelador.svg'
import { ReactComponent as FilterIcon } from '../../assets/svg/BuyerOperacion/filter.svg'
import { ReactComponent as MapIcon } from '../../assets/svg/BuyerOperacion/map.svg'
import { ReactComponent as EditIcon } from '../../assets/svg/BuyerOperacion/edit.svg'
import { ReactComponent as EurIcon } from '../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'





import { ReactComponent as Granada } from '../../assets/svg/Juice/Pomegranat.svg'
import { ReactComponent as Citrico } from '../../assets/svg/Juice/Citrico.svg'
import { ReactComponent as Lima } from '../../assets/svg/Juice/Lima.svg'
import { ReactComponent as Limon } from '../../assets/svg/Juice/Lemon.svg'
import { ReactComponent as Pomelo } from '../../assets/svg/Juice/Pomelo.svg'
import { ReactComponent as Mandarina } from '../../assets/svg/Juice/Mandarin.svg'
import { ReactComponent as Naranja } from '../../assets/svg/Juice/Naranja.svg'
import { ReactComponent as Jengibre } from '../../assets/svg/Juice/Jengibre.svg'
import { ReactComponent as JengibreLimon } from '../../assets/svg/Juice/JingLemon.svg'






import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { mainApi } from '../../store/services/mainApi'


import BuyerProductionList from "./Switch/List/BuyerProductionList";
import Stats from "../Stats/Stats";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import ButtonGroup from '@mui/material/ButtonGroup';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import theme from "../../utils/themes/mainTheme";
import LinearProgress from '@mui/material/LinearProgress';


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}


function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ').length < 2 ? name.split(' ')[0][0] : name.split(' ')[0][0] + "" + name.split(' ')[1][0] }`,
    };
}


export default function BuyerProduction() {
    useEffect(() => {
        document.title = 'Items Shopping'
    }, [])
    const [selectedDeliveryDates, setSelectedDeliveryDates] = useState([]);
    const [rows, setRows] = useState([]);

    const [pavillionsArr, setPavillionsArr] = useState([]);

    const [changeCompradorOrderId, setChangeCompradorOrderId] = useState([])

    const [changeBuyerPavilionObj] = mainApi.useChangeBuyerOrderPavilionObjMutation()
    const [changeBuyerQuantityObj] = mainApi.useChangeBuyerOrderQuantityObjMutation()
    const [changeBuyerPriceObj] = mainApi.useChangeBuyerOrderPriceObjMutation()

    const { data: statusesData, isLoading3 } = mainApi.useGetAllStatusesQuery()
    // const { data: drivers } = mainApi.useGetDriversQuery()

    const [statuses, setStatuses] = useState([])
    const [statusModal, setStatusModal] = useState(false)
    const [deliveryDateModal, setDeliveryDateModal] = useState(false)

    const [editChange, setEditChange] = useState(false)


    useEffect(() => {
        if (statusesData) {
            setStatuses(statusesData.filter((status) => status.type === 'itemsInOrder' || status.type === 'buyer'))
        }
    }, [statusesData])

    const mobileScreen = useMediaQuery('(max-width: 768px)')
    const [selectedTradeNames, setSelectedTradeNames] = useState([]);
    const [selectedZumos, setSelectedZumos] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);



    const [openRows, setOpenRows] = useState({});
    const [selectedItems, setSelectedItems] = useState([]); // Holds selected items
    const [switchChecker, setSwitchChecker] = useState(false);
    const [switchClientZumos, setSwitchClientZumos] = useState(2);
    const [isSortedByNewest, setIsSortedByNewest] = useState(true);
    const [search, setSearch] = useState('')


    const [buttonFilters, setButtonFilters] = useState({
        Crist: false,
        Plast: false,
        PLM: false,
        PLT: false,
        Granada: false,
        Naranja: false,
        Citrico: false,
        GINGER: false,
        LIMA: false,
        LIMON: false,
        POMELO: false,
        Mandarina: false,
        Jengibre: false
    });


    const toggleButtonFilter = (filterName) => {
        setButtonFilters(prevState => ({
            ...prevState,
            [filterName]: !prevState[filterName]
        }));
    };


    const handleAllItemsSelect = () => {
        if(selectedItems.length == allItemsArray.length){
            setSelectedItems([]);
        }else{
            setSelectedItems(allItemsArray)
        }
    }


    const updatePavilionHandler = (pav, item) => {

        changeBuyerPavilionObj({
            orders: [item],
            pavilion: pav
        })
            .unwrap()
            .then((res) => {
                // setChangeCompradorOrderId([])
            })
    }

    const updateQuantityHandler = (pav, item) => {

        changeBuyerQuantityObj({
            orders: [item],
            quantityBuyer: pav
        })
            .unwrap()
            .then((res) => {
                setChangeCompradorOrderId([])
            })
    }
    const updatePriceHandler = (pav, item) => {

        changeBuyerPriceObj({
            orders: [item],
            marketPriceBuyer: pav
        })
            .unwrap()
            .then((res) => {
                setChangeCompradorOrderId([])
            })
    }

    const handleNestedSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemExists = prevItems.some(i =>
                (i._id + i.items._id) === (item._id + item.items._id)
            );

            if (isChecked) {
                // Add item if checked and not already in selectedItems
                if (!itemExists) {
                    return [...prevItems, item];
                }
                return prevItems;
            } else {
                // Remove item if unchecked
                return prevItems.filter(i =>
                    (i._id + i.items._id) !== (item._id + item.items._id)
                );
            }
        });
    };

    const handleTopLevelSelect = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = item.orders.map(order => order._id + order.items._id);

            if (isChecked) {
                // Add all orders from this item if checked
                const newItems = item.orders.filter(order =>
                    !prevItems.some(i => (i._id + i.items._id) === (order._id + order.items._id))
                );
                return [...prevItems, ...newItems];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };

    const handleTopLevelSelectAlone = (event, item) => {
        const isChecked = event.target.checked;

        setSelectedItems((prevItems) => {
            const itemOrderIds = [item._id + item.items._id]

            if (isChecked) {
                // Add all orders from this item if checked
                return [...prevItems, item];
            } else {
                // Remove all orders from this item if unchecked
                return prevItems.filter(i =>
                    !itemOrderIds.includes(i._id + i.items._id)
                );
            }
        });
    };



// Toggle open state for individual rows
    const toggleRowOpen = (itemId) => {
        setOpenRows((prevOpenRows) => ({
            ...prevOpenRows,
            [itemId]: !prevOpenRows[itemId]
        }));
    };



    const { data: uniqueZumos, isLoading } = mainApi.useGetItemsOperacionQuery(
        {

        },
        //  { pollingInterval: 5000 },
    )
    console.log(uniqueZumos)



    // const { data: uniqueZumos1, isLoading1 } = mainApi.useGetZumosFirstQuery(
    //     {
    //
    //     },
    //    // { pollingInterval: 5000 },
    // )



    // useEffect(() => {
    //     if(!isLoading){
    //         setUniqueZumos(uniqueZumosPoll)
    //     }
    // }, [isLoading, uniqueZumosPoll]);

    const normalizeText = (text) =>
        text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();


    useEffect(() => {
        if (!isLoading) {
            const filteredRows = uniqueZumos.map(el => {
                const filteredOrders = el.orders.filter(e => {

                    if(
                        (selectedTradeNames.length === 0 || selectedTradeNames.includes(e.tradeName))
                        && (search.length == 0 || normalizeText(e.items.name).includes(normalizeText(search)) || normalizeText(e.tradeName).includes(normalizeText(search)))
                        && ( selectedZumos.length === 0 ||
                            selectedZumos.some((zumo) =>
                                normalizeText(e.items.name).includes(normalizeText(zumo))
                            ))
                        && ( selectedStatus.length === 0 ||
                            selectedStatus.some((status) =>
                                e.items?.statusBuyer?.name == status
                            ))
                        && ( pavillionsArr.length === 0 ||
                            pavillionsArr.some((pav) => {
                                    if (Array.isArray(e.items?.pavilion)) {
                                        console.log("hihih");
                                        return (e.items?.pavilion.some(el => String(el).toLowerCase() == pav.toLowerCase())) || (e.items?.pavilion && e.items?.pavilion.some(el => String(el)[0] == pav[0]))

                                    }
                                }
                            ))


                    ){

                        const matchesPLM = buttonFilters.PLM ? e.deliveryTime.includes("PLM") : true;
                        const matchesPLT = buttonFilters.PLT ? e.deliveryTime.includes("PLT") : true;
                        const matchesDeliveryDate = selectedDeliveryDates.length
                            ? selectedDeliveryDates.some(date => e.deliveryDate.includes(date))
                            : true;

                        return matchesPLM && matchesPLT && matchesDeliveryDate;
                    }else{
                        return false
                    }
                });

                return filteredOrders.length > 0 ? { ...el, orders: filteredOrders } : null;
            }).filter(el => el !== null);

            const fullyFilteredRows = filteredRows.filter(el => {
                const matchesOtherFilters = Object.keys(buttonFilters).every(filter => {
                    if (buttonFilters[filter] && filter !== 'PLM' && filter !== 'PLT') {
                        return el.orders.some(e => normalizeText(e.items.name).includes(normalizeText(filter)));
                    }
                    return true;
                });

                const matchesSearch = el.orders.some(e =>
                    normalizeText(e.items.name).includes(normalizeText(search)) ||
                    normalizeText(e.tradeName).includes(normalizeText(search))
                );

                return matchesOtherFilters && matchesSearch;
            });

            const sortedRows = fullyFilteredRows.sort((a, b) => {
                const dateA = Math.max(...a.orders.map(e => e.date));
                const dateB = Math.max(...b.orders.map(e => e.date));
                return isSortedByNewest ? dateB - dateA : dateA - dateB;
            });

            setRows(sortedRows);
        }
    }, [uniqueZumos, search, buttonFilters, isLoading, isSortedByNewest, selectedDeliveryDates, selectedTradeNames, selectedZumos, selectedStatus, pavillionsArr]);



    const fakeRows = useMemo(() => {
        // Шаг 1: Модификация sku для каждого элемента
        const modifiedRows = rows.map(item => ({
            ...item,
            sku: item.sku.replace("plast", "").replaceAll("1", "")
        }));

        // Шаг 2: Объединение элементов с одинаковым sku
        const mergedRows = modifiedRows.reduce((acc, item) => {
            const existingItem = acc.find(i => i.sku === item.sku);

            if (existingItem) {
                // Объединяем свойства для одинаковых sku
                existingItem.orders = existingItem.orders.concat(item.orders);
                existingItem.totalPrice += item.totalPrice;
                existingItem.totalQuantity += item.totalQuantity;
            } else {
                // Если элемента с таким sku еще нет, добавляем его в массив
                acc.push({ ...item });
            }

            return acc;
        }, []);

        // Выводим результат в консоль для проверки
        // mergedRows.forEach(e => console.log(e));

        return mergedRows;
    }, [rows]);



    const groupedClients = useMemo(() => {
        // Шаг 1: Группировка заказов по клиентам
        const clientOrders = rows.reduce((acc, row) => {
            row.orders.forEach(order => {
                const clientId = order.userAcc.id;

                if (!acc[clientId]) {
                    acc[clientId] = {
                        clientInfo: {
                            id: clientId,
                            name: order.userAcc.name,
                            tradeName: order.userAcc.tradeName,
                            phone: order.userAcc.phone,
                            email: order.userAcc.email
                        },
                        products: {}
                    };
                }

                // Проход по продуктам в заказе
                const item = order.items;
                const modifiedSku = item.sku.replace("plast", "").replaceAll("1", "");

                if (!acc[clientId].products[modifiedSku]) {
                    acc[clientId].products[modifiedSku] = {
                        ...item,
                        sku: modifiedSku,
                        totalQuantity: 0,
                        totalPrice: 0,
                        orders: []
                    };
                }

                acc[clientId].products[modifiedSku].totalQuantity += item.quantity;
                acc[clientId].products[modifiedSku].totalPrice += item.totalPrice || 0;
                acc[clientId].products[modifiedSku].orders.push(order);
            });

            return acc;
        }, {});

        // Конвертирование объекта в массив с необходимой структурой
        return Object.values(clientOrders).map(client => ({
            ...client,
            products: Object.values(client.products)
        }));
    }, [rows]);


    console.log(groupedClients)

    const allFixedItemsArray = useMemo(() => {
        if(!isLoading){
            return uniqueZumos.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [isLoading, uniqueZumos])


    const allItemsArray = useMemo(() => {
        if(!isLoading){
            return rows.map(el => el.orders.map(e => e)).flat();
        }else{
            return []
        }

    }, [rows])

    const personalItems = useMemo(() => {
        return allItemsArray
            .slice()
            .sort((a, b) => new Date(a.date) - new Date(b.date))
            .filter((item) =>
                item.items?.comprador?.some((el) =>
                    JSON.parse(localStorage.getItem("acc")).phoneNumber
                        .replaceAll(" ", "")
                        .includes(el.phone.replaceAll(" ", ""))
                )
            );
    }, [allItemsArray]);

    console.log(personalItems)



    const toggleDeliveryDateFilter = (date) => {
        setSelectedDeliveryDates(prevDates =>
            prevDates.includes(date)
                ? prevDates.filter(d => d !== date)
                : [...prevDates, date]
        );
    };


    const mock = [
        {
            image: 'Cart',
            title: 'Total Items',
            value: personalItems.length,
            color: 'rgb(92, 195, 253)',
        },
        {
            image: 'GreenCart',
            title: 'Reservado',
            value: personalItems.filter((acc, item) => acc?.items?.statusBuyer?.name == "Reservado").length,
            color: 'rgb(0, 171, 85)',
        },
        {
            image: 'RedCart',
            title: 'Comprar',
            value: personalItems.filter((acc, item) => acc?.items?.statusBuyer?.name == "Comprar").length,
            color: 'red',
        },
        {
            image: 'Cog',
            title: 'Cargado',
            value: personalItems.filter((acc, item) => acc?.items?.statusBuyer?.name == "Cargado").length,
            color: '#0E8C15',
        },
        // {
        //     image: 'Cog',
        //     title: 'Loaded',
        //     value: personalItems.filter((acc, item) => acc),
        //     color: '#0E8C15',
        // },
        {
            image: 'Catalog',
            title: 'Pagado',
            value: personalItems.filter((acc, item) => acc?.items?.statusBuyer?.name == "Pagado").length,
            color: 'rgb(0, 171, 85)',
        },
        {
            image: 'Catalog',
            title: 'Pagado',
            value:  personalItems.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0).toFixed(2) + "€",
            color: 'rgb(0, 171, 85)',
        },
        // {
        //     image: 'Spo',
        //     title: 'Average Price',
        //     value: (allItemsArray.reduce((accumulator, currentValue) => parseFloat(currentValue.items.marketPriceCP * currentValue.items.quantity) + accumulator, 0) / allItemsArray.reduce((acc, item) => acc + parseFloat(item.items.quantity), 0)).toFixed(2) + "€",
        //     color: 'rgb(92, 195, 253)',
        // },
    ]


    const juiceBackground = (juice) => {
        switch (juice) {
            case 'Granada':
                return <Granada width={20} height={20} />
            case 'Citrico':
                return <Citrico width={20} height={20} />
            case 'Lima':
                return <Lima width={20} height={20} />
            case 'Limon':
                return <Limon width={20} height={20} />
            case 'Pomelo':
                return <Pomelo width={20} height={20} /> //Production
            case 'Mandarina':
                return <Mandarina fill='#6D26E1' width={20} height={20} />
            case 'Naranja':
                return <Naranja width={20} height={20} />
            case 'Jengibre':
                return <Jengibre width={20} height={20} />
            case 'Jengibre20/Limon80':
                return <JengibreLimon width={20} height={20} />
            default:
                return null
        }
    }


    const statusBackground = (status) => {
        switch (status) {
            case 'Ready':
                return <Like width={20} height={20} />
            case 'PROCESSING':
                return <InProgress width={20} height={20} />
            case 'NOT AVAILABLE':
                return '⛔'
            case 'CHECKING':
                return '👁️'
            case 'PRODUCTION':
                return <Production width={20} height={20} /> //Production
            case 'SHOPPING':
                return <Delivery fill='#6D26E1' width={20} height={20} />
            case 'CONGEDOR':
                return <CONGEDOR width={20} height={20} />
            case 'DEVOLUCION':
                return '🔙'
            case 'CONFIRMAR':
                return '👍'
            case 'RESERVED':
                return '📌'
            case 'Cargado':
                return <CarIcon width={20} height={20}/>
            case 'Pagado':
                return <EurIcon width={20} height={20}/>
            case 'Reservado':
                return <GreenCartIcon width={20} height={20}/>
            case 'Comprar':
                return <RedCartIcon width={20} height={20}/>
            default:
                return null
        }
    }

    const uniqueProveedors = useMemo(() => {
        if(!isLoading){
            return [...new Set(allItemsArray.map(e => e.items.proveedor))]

        }else{
            return []
        }
    }, [allItemsArray, isLoading]);


    const uniqueDeliveryDates = useMemo(() => {
        if(!isLoading){
            return [...new Set(allItemsArray.map(e => (e.deliveryDate.split(",")[0].replaceAll(", PLT🌙", "").replaceAll(", PLT 🌛", "").replaceAll(", PLM ☀️", ""))))]

        }else{
            return []
        }
    }, [allItemsArray, isLoading]);



    const optionsAutoComplete = useMemo(() => {
        if (!isLoading && allFixedItemsArray.length) {
            const uniqueItems = new Map(); // Используем Map для сохранения уникальных tradeName
            allFixedItemsArray.forEach(el => {
                if (!uniqueItems.has(el.tradeName)) {
                    uniqueItems.set(el.tradeName, { label: el.tradeName, id: el._id });
                }
            });
            return Array.from(uniqueItems.values());
        }
        return [];
    }, [isLoading, allFixedItemsArray]);


    const handleTradeNameChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedTradeNames(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleZumosChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedZumos(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleStatusChange = (el) => {
        console.log(el)

        setSelectedStatus(prev => prev.includes((el)) ? prev.filter(name => (el) !== name) : [...prev, el])
        if(el == "AllIcon"){
            setSelectedStatus([])
        }
        console.log(selectedStatus)
    };



    const [zumosFilter, setZumosFilter] = useState([
        {label: "Granada", emoji: "🍎"},
        {label: "Citrico", emoji: "🍋"},
        {label: "Lima", emoji: "🍈"},
        {label: "Limon", emoji: "🍋"},
        {label: "Pomelo", emoji: "🍊"},
        {label: "Mandarina", emoji: "🍊"},
        {label: "Naranja", emoji: "🍊"},
        {label: "Jengibre", emoji: "🍊"},
        {label: "Jengibre20/Limon80", emoji: "🍊"},
    ])


    const rowStyle = {
        height: "50px",
        // backgroundColor: "rgba(23, 99, 24, .902)",
        color: "black",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal",

    }

    const rowStyleMap = {
        color: "black",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "1.6px",
        lineHeight: "normal",
        // background: "#ECFFED"
    }

    const checkBoxStyle = {
        color: "rgb(102, 187, 106)",
        '&.Mui-checked': {
            color: "rgb(102, 187, 106)",
        }
    }

    const switchStyle = {
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "rgb(102, 187, 106)",
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "rgb(102, 187, 106)",
        },
    }
    console.log(uniqueProveedors)
    const theme = createTheme({
        palette: {
            ochre: {
                main: "#ECFFED",
                light: '#ECFFED',
                dark: '#ECFFED',
            },
        },
    });


    const [progress, setProgress] = React.useState(10);

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    //     }, 800);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);



    const uniqueCompr = useMemo(() => {
        let names = allItemsArray.map(item => item.items?.comprador && item.items?.comprador.map(el => el.name)).flat()
        return names.filter(function(item, pos) {
            if(item){
                return names.indexOf(item) == pos;
            }
        })

    }, [allItemsArray])


    return (
        <Container sx={{
            marginBottom: selectedItems.length && mobileScreen && "300px",
            zIndex: "1",
            maxWidth: {
                xs: '100%', // для маленьких экранов
                sm: '100%', // для экранов шире 600px
                md: '100%', // для экранов шире 960px
                lg: '100%', // для экранов шире 1280px
                xl: '100%' // для экранов шире 1920px
            }
        }}>
            <Box>
                <Stats mock={mock}/>

                <FormControl style={{display: 'flex', color: "#2F7330"}}>
                    <Box sx={{
                        mb: 1,
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: "nowrap"
                    }}>
                        <Box>
                            <Box>Buyers</Box>
                            <AvatarGroup sx={{justifyContent: "center"}} max={3} align="left">

                                {uniqueCompr.map((el, i) => {
                                    return (

                                            <Avatar
                                                key={i} {...stringAvatar(el)}/>

                                    )
                                })

                                }
                            </AvatarGroup>
                        </Box>
                        <TextField sx={{
                            m: 1,
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderRadius: `7px`,
                                },
                            },  }}
                                   id="outlined-basic" label="Search" variant="outlined" color='success'
                                   onChange={(e) => setSearch(e.target.value)}/>


                    </Box>
                    <Box sx={{
                        mb: 1,
                        display: "flex",
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignContent: "right",
                        flexWrap: "wrap",
                        width: "100%"
                    }}>
                        {/*<Checkbox*/}
                        {/*    // Checked if all nested orders are selected*/}
                        {/*    sx={checkBoxStyle}*/}
                        {/*    checked={selectedItems.length == allItemsArray.length}*/}
                        {/*    onChange={(event) => handleAllItemsSelect()}*/}
                        {/*/>*/}
                        <Box sx={{display: "flex", gap: "10px", alignItems: 'center', flexWrap: "wrap"}}>
                            <Box>
                                <Box>Pavilions</Box>
                                <ThemeProvider sx={{display: "flex", flexDirection: "row"}} theme={theme}>
                                    <ButtonGroup sx={{m: 1, borderRadius: "7px", border: "1px solid #D9D9D9", flexWrap: "wrap", justifyContent: "center"}} variant="outlined" aria-label="Basic button group">

                                        {
                                            [
                                                {name: "1A", color: "#5FC56E"},
                                                {name: "2B", color: "#FF2000"},
                                                {name: "3C", color: "#FF8C04"},
                                                {name: "4D", color: "#0351FF"},
                                                {name: "5E", color: "#B403FF"},
                                                {name: "6F", color: "#A41010"},
                                                {name: "7G", color: "#06C0B0"},
                                                {name: "Polivalent", color: "#4C4C4C"}


                                            ].map((el, ind) =>
                                                <Button key={ind} onClick={() => setPavillionsArr(prev => prev.includes(el.name) ? prev.filter(name => el.name !== name) : [...prev, el.name])} variant={pavillionsArr.includes(el.name) ? "contained" : "text"}  color="ochre" sx={{color: el.color, borderRadius: (el.name == "1A" && "7px 0px 0px 7px" || el.name == "Polivalent" && "0px 7px 7px 0px")}}>{el.name}</Button>

                                            )
                                        }
                                    </ButtonGroup>
                                </ThemeProvider>

                            </Box>
                            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: 'center', flexWrap: "nowrap"}}>
                                <Box>
                                    <Box>Progress</Box>
                                    <ThemeProvider sx={{display: "flex", flexDirection: "row"}} theme={theme}>
                                        <ButtonGroup sx={{m: 1, borderRadius: "7px", border: "1px solid #D9D9D9", flexWrap: "wrap", justifyContent: "center"}} variant="outlined" aria-label="Basic button group">

                                            {
                                                [
                                                    {name: "All", alt: "AllIcon", color: "#2F7330"},
                                                    {name: "0%", alt: "Comprar",color: "#FF725E"},
                                                    {name: "50%", alt: "Reservado",color: "#FFC727"},
                                                    {name: "75%", alt: "Pagado",color: "#7C03FF"},
                                                    {name: "100%", alt: "Cargado",color: "#5FC56E"},

                                                ].map((el, ind) =>
                                                    <Button key={ind} onClick={() => {
                                                        handleStatusChange(el.alt);
                                                    }
                                                    }
                                                            variant={selectedStatus.includes((el?.alt ? el.alt : el.name)) ? "contained" : "text"}  color="ochre" sx={{height: "36.5px", color: el.color, borderRadius: (el.name == "1A" && "7px 0px 0px 7px" || el.name == "Polivalent" && "0px 7px 7px 0px")}}>{el.name}
                                                    </Button>
                                                )
                                            }
                                        </ButtonGroup>
                                    </ThemeProvider>

                                </Box>
                                <Box>
                                    <Box>Status</Box>
                                    <ThemeProvider sx={{display: "flex", flexDirection: "row"}} theme={theme}>
                                        <ButtonGroup sx={{m: 1, borderRadius: "7px", border: "1px solid #D9D9D9", flexWrap: "wrap", justifyContent: "center"}} variant="outlined" aria-label="Basic button group">

                                            {
                                                [
                                                    {name: "All", alt: "AllIcon", color: "#2F7330"},
                                                    {name: (<CarIcon width={30} height={30}/>), alt: "Cargado", color: "#5FC56E"},
                                                    {name: (<EurIcon width={25} height={25}/>), alt: "Pagado", color: "#5FC56E"},
                                                    {name: (<GreenCartIcon width={30} height={30}/>), alt: "Reservado", color: "#5FC56E"},
                                                    {name: (<RedCartIcon width={30} height={30}/>), alt: "Comprar", color: "#5FC56E"}



                                                ].map((el, ind) =>
                                                    <Button key={ind} onClick={() => {
                                                        handleStatusChange(el.alt);
                                                    }
                                                    }
                                                            variant={selectedStatus.includes((el?.alt ? el.alt : el.name)) ? "contained" : "text"}  color="ochre" sx={{height: "36.5px", color: el.color, borderRadius: (el.name == "1A" && "7px 0px 0px 7px" || el.name == "Polivalent" && "0px 7px 7px 0px")}}>{el.name}
                                                    </Button>

                                                )
                                            }
                                        </ButtonGroup>
                                    </ThemeProvider>

                                </Box>
                            </Box>
                        </Box>
                        <Box  sx={{display: "flex", justifyContent: "flex-end", alignItems: 'center'}}>
                            <Box>
                                <Box sx={{mr: 1, textAlign: "right"}}>Actions</Box>
                                <Box sx={{
                                    m: 1,
                                    display: "flex",
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: "nowrap",
                                    gap: "10px"
                                }}>
                                    <MapIcon width={30} height={30}/>
                                    {editChange ?
                                        <Button onClick={() => setEditChange(prev => !prev)} color={"success"} variant={"outlined"}>Back</Button> :
                                        <EditIcon onClick={() => setEditChange(prev => !prev)} width={30} height={30}/>}
                                    <FilterIcon width={30} height={30}/>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{width: '100%' }}>


                            {/*<div className='progress__bottom'>*/}
                            {/*    <LinearProgress*/}
                            {/*        sx={{*/}
                            {/*            width: '100%',*/}
                            {/*            height: '15px',*/}
                            {/*            '& .MuiLinearProgress-bar': {backgroundColor: '#2E732F'},*/}
                            {/*            '& .MuiLinearProgress-determinate': {backgroundColor: '#176318'},*/}
                            {/*        }}*/}
                            {/*        variant='determinate'*/}
                            {/*        color='success'*/}
                            {/*        value={10}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<Progress*/}
                            {/*    progressItems={progressItems}*/}
                            {/*    productionItems={productionItems}*/}
                            {/*    shoppingItems={shoppingItems}*/}
                            {/*    order={order}*/}
                            {/*    favoriteItems={favoriteItems}*/}
                            {/*    setFilterItemsValue={setFilterItemsValue}*/}
                            {/*/>*/}
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ width: '100%', mr: 1 }}>
                                    <LinearProgress color="success" variant="determinate" value={(Math.round(100))} />
                                </Box>
                                <Box sx={{ minWidth: 35 }}>
                                    {`${(Math.round(100))}%`}

                                </Box>
                            </Box>
                        </Box>
                    </Box>

                </FormControl>
                {isLoading ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </Box>
                ) : (
                    <BuyerProductionList
                        updatePavilionHandler={updatePavilionHandler}
                        updatePriceHandler={updatePriceHandler}
                        updateQuantityHandler={updateQuantityHandler}
                        setEditChange={setEditChange}
                        editChange={editChange}
                        allItemsArray={allItemsArray}
                        statusBackground={statusBackground}
                        handleAllItemsSelect={handleAllItemsSelect}
                        switchChecker={switchChecker}
                        setSwitchChecker={setSwitchChecker}
                        rows={rows}
                        selectedItems={selectedItems}
                        handleTopLevelSelectAlone={handleTopLevelSelectAlone}
                        toggleRowOpen={toggleRowOpen}
                        openRows={openRows}
                        handleNestedSelect={handleNestedSelect}
                        rowStyle={rowStyle}
                        rowStyleMap={rowStyleMap}
                        checkBoxStyle={checkBoxStyle}
                        switchStyle={switchStyle}
                        statuses={statuses}
                        mobileScreen={mobileScreen}
                    />
                )}
            </Box>

        </Container>
    )
}



