import React, { useEffect, useState, useMemo } from 'react'
import './ContactChange.scss'
import { ReactComponent as ArrowLeft } from '../../../assets/svg/ArrowLeft.svg'

import User from './User/User'
import UserInfo from './UserInfo/UserInfo'
import Margen from './Margen/Margen'
import TopOrders from './TopOrders/TopOrders'
import Table from '../../Table/Table'
// import { ReactComponent as Instagram } from '../../../assets/svg/Instagram.svg'

import { renderComponent } from '../../../utils/orderFunctions'
import { renderModal } from '../../../utils/orderModal'

import { useLocation, useNavigate } from 'react-router-dom'

import { mainApi } from '../../../store/services/mainApi'
import { Box, Button, CircularProgress, Link, Modal, Typography } from '@mui/material'
import FiltersInfo from './FiltersInfo/FiltersInfo'
import Rentabilidad from './Rentabilidad/Rentabilidad'
import OrdersTable from '../../Orders/OrderTable/OrderTable'
import AdditionalInfo from './AdditionalInfo/AdditionalInfo'

import { useDispatch, useSelector } from 'react-redux'
import { changeCurrentContact, changeSave } from '../../../store/slices/changeContact'
import PersonalSales from './PerconalSales/PersonalSales'
import Carts from './Carts/Cart'
import { findDifferences, logAction } from '../../../utils/logsAction/logService'

export default function ContactChange() {
  const location = useLocation()
  const dispatch = useDispatch()
  const { data: contactData, isLoading } = mainApi.useGetContactsByIdQuery(location.pathname.split('/').pop(), {
    skip: !location.pathname.split('/').pop(),
  })
  const {
    data: ordersData,
    isLoading: isLoadingOrders,
    isFetching: isFetchingOrders,
  } = mainApi.useGetOrdersByTradeNameFiltersQuery(
    {
      tradeName: contactData?.tradeName ? contactData?.tradeName : '',
    },
    {
      skip: !contactData?.tradeName,
      refetchOnMountOrArgChange: true,
    },
  )
  const { data: contactsData, isLoading: isLoadingContacts } = mainApi.useGetAllContactsQuery()
  const [orders, setOrders] = useState([])
  const [contacts, setContacts] = useState([])
  const [contact, setContact] = useState([])
  const [userOrders, setUserOrders] = useState([])
  const navigate = useNavigate()
  const usr = JSON.parse(localStorage.getItem('acc'))

  const reduxContact = useSelector((state) => state.changeContact.currentContact)

  //filters
  const [perPage, setPerPage] = useState(50)
  const [searchValue, setSearchValue] = useState('')
  const [status, setStatus] = useState([])

  const [dateRange, setDateRange] = useState('')

  const [tradeNames, setTradeNames] = useState([])

  const [productsStatus, setProductsStatus] = useState('')

  const [searchPlatform, setSearchPlatform] = useState('')

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  //change info
  // const save = useSelector((state) => state.currentContact.save)
  const [change, setChange] = useState(false)

  const [loadingOrders, setLoadingOrders] = useState(true)

  const [askModal, setAskModal] = useState(false)

  const [updateContact] = mainApi.useUpdateContactMutation()

  const handleSave = () => {
    let updatedContact = {
      ...reduxContact,
      id: reduxContact._id,
    }
    delete updatedContact._id
    updateContact({
      data: {
        ...updatedContact,
      },
    }).then(() => {
      dispatch(changeSave(false))
      setAskModal(false)
      setChange(false)
      dispatch(mainApi.util.invalidateTags(['Contact']))

      logAction('Update contact', {
        dif: findDifferences(contact, updatedContact),
        user: {
          ...usr,
          actions: {},
        },
      })
    })
  }

  const handleOpenAskModal = () => {
    setAskModal(true)
    dispatch(changeSave(true))
  }

  const handleCloseAskModal = () => {
    setAskModal(false)
    dispatch(changeSave(false))
  }

  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData)
    }
  }, [contactsData])

  useEffect(() => {
    if (contactData) {
      setContact(contactData)
      dispatch(changeCurrentContact(contactData))
      document.title = contactData?.name ? contactData?.name : 'Contact'
    }
  }, [contactData])

  useEffect(() => {
    if (ordersData) {
      setOrders(ordersData)
    }
  }, [ordersData])

  const currentOrders = useMemo(() => {
    console.log(contact.type, 'contact type')
    if (contact) {
      return orders.filter((el) => {
        if (contact.type === 'client') {
          return el?.tradeName === contact?.tradeName || el.userAcc?.tradeName === contact?.tradeName
        }
        if (contact.type === 'userUnion') {
          return el.msg?._data?.from.split('@')[0] === contact?.phone || el.userAcc?.phone === contact?.phone
        }
        if (contact.type === 'user' && contact.platform === 'whatsapp') {
          return 'msg' in el && el.msg?._data?.from.split('@')[0] === contact?.phone
        }
        if (contact.type === 'user' && contact.platform === 'telegram') {
          return 'tg' in el && el.userAcc?.phone === contact?.phone
        }
        if (contact.type === 'user' && contact.platform === 'browser') {
          return el.userAcc?.phone === contact?.phone
        }

        return false
      })
    }
    return []
  }, [contact, orders])

  useEffect(() => {
    if (currentOrders) {
      setUserOrders(currentOrders)
      setLoadingOrders(false)
    }
    console.log(currentOrders, 'currentOrders')
  }, [currentOrders])

  return isLoading || isLoadingOrders || isLoadingContacts ? (
    <CircularProgress
      size={200}
      color='success'
      sx={{
        position: 'absolute',
        top: '45%',
        left: '45%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  ) : (
    <div className='contactChange'>
      <div className='contactChange__title'>
        <ArrowLeft className='contactChange__title__back' onClick={() => navigate(-1)} />
        <h2>{contact.name}</h2>
        {change ? (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '15px' }}>
            <Button onClick={() => handleOpenAskModal()} variant='contained' color='success'>
              Save
            </Button>
            <Button variant='contained' color='error' onClick={() => setChange(false)}>
              Cancel
            </Button>
          </Box>
        ) : (
          <Button
            onClick={() => setChange(true)}
            sx={{
              marginLeft: '30px',
            }}
            variant='contained'
            color='success'
          >
            Change
          </Button>
        )}
        <Link
          sx={{
            width: 'max-content',
            marginLeft: '20px',
            textDecoration: 'none',
            color: 'black',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid black',
            cursor: 'pointer',
          }}
          href={`https://tpoapp.com/account/${contact.tradeName}`}
          target='_blank'
          // className='contactChange__title__back'
        >
          Generate page for client
        </Link>
      </div>
      <Modal open={askModal} onClose={handleCloseAskModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,

            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Do you want to save changes?
          </Typography>
          <Button onClick={handleSave} variant='contained' color='success'>
            Save
          </Button>
          <Button onClick={handleCloseAskModal} variant='contained' color='error'>
            Cancel
          </Button>
        </Box>
      </Modal>
      <div className='contactChange__body'>
        <div className='contactChange__body__top'>
          <div className='contactChange__body__top__left'>
            <div className='contactChange__body__top__left__top'>
              <User contact={contact}></User>
              <UserInfo change={change} userOrders={userOrders} contact={contact}></UserInfo>
            </div>
            <div className='contactChange__body__top__left__bottom'>
              <FiltersInfo contact={contact} />
              <Rentabilidad />
              <div className='contactChange__body__top__left__bottom__item'>
                <AdditionalInfo change={change} contact={contact} />
              </div>
              <div>
                <PersonalSales contact={contact} />
                <Carts contact={contact} />
              </div>
            </div>
          </div>
          {/* <div className='contactChange__body__top__right'><TopOrders userOrders={userOrders} contact={contact} contacts={contacts} /></div> */}
        </div>
        <div className='contactChange__body__bottom'>
          {loadingOrders ? (
            <CircularProgress />
          ) : (
            <OrdersTable
              btntype={'ordersChange'}
              data={currentOrders}
              setData={setOrders}
              contacts={contacts}
              perPage={perPage}
              setPerPage={setPerPage}
              isFetchingOrders={isFetchingOrders}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              status={status}
              setStatus={setStatus}
              dateRange={dateRange}
              setDateRange={setDateRange}
              tradeNames={tradeNames}
              setTradeNames={setTradeNames}
              setProductsStatus={setProductsStatus}
              productsStatus={productsStatus}
              searchPlatform={searchPlatform}
              setSearchPlatform={setSearchPlatform}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          )}
        </div>
      </div>
    </div>
  )
}
