import React, { useEffect, useMemo, useState } from 'react'
import './UserInfo.scss'

import { ReactComponent as Turnover } from '../../../../assets/svg/Turnover.svg'
import { ReactComponent as ProfitIcon } from '../../../../assets/svg/ProfitIcon.svg'
import { ReactComponent as TiempoIcon } from '../../../../assets/svg/TiempoIcon.svg'
import { ReactComponent as DebitIcon } from '../../../../assets/svg/DebtIcon.svg'
import {InputLabel, Select, TextField, MenuItem, Autocomplete, Button, Checkbox, Modal, Box} from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch, useSelector } from 'react-redux'
import { changeContact } from '../../../../store/slices/changeContact'

export default function UserInfo({ userOrders, contact, change }) {
  const {
    name,
    username,
    employee,
    tradeName: client,
    responsable,
    enabled,
    type,
    tradeName,
    adminClient,
    personalPhones,
    pensonalPhonesWithNames,
  } = contact
  const reduxContact = useSelector((state) => state.changeContact.contact)

  //change business name

  const dispatch = useDispatch()
  const [changeBuisnessToggle, setChangeBuisnessToggle] = useState(false)

  const [statusModal, setStatusModal] = useState(false)

  const [changeBuisness] = mainApi.useChangeBuisnessMutation()
  const [sendBotStartWhatsapp] = mainApi.useSendBotStartWhatsappMutation()


  const handleSendBotStartWhatsapp = (item) => {
    sendBotStartWhatsapp({
      phone: prompt("Approved Phone Number", item.phone.replaceAll(" ", "")) || null,
      name: prompt("Approved Name", item.name )|| null,
      employee: prompt("Approved Commercial Name", contact.employee) || null
    })
        .then((res) => {
          if(res.error.data == "ok"){
            alert("ok")
          }else{
            alert("Cancel")
          }
        })
  }

  // const { data: contacts, isSuccess } = mainApi.useGetAllContactsQuery()
  const { data: tradeNames, isSuccess } = mainApi.useGetTradeNamesFromContactsQuery()

  const handleChangeBuisness = (buisness) => {
    changeBuisness({
      data: {
        _id: contact._id,
        tradeName: buisness,
      },
    }).then(() => {
      dispatch(mainApi.util.invalidateTags(['Contact']))
    })
    setChangeBuisnessToggle(false)
  }

  //change contact info

  const save = useSelector((state) => state.changeContact.save)

  const [typeContact, setTypeContact] = useState(type)

  const [changeName, setChangeName] = useState('')
  const [changeUsername, setChangeUsername] = useState('')
  const [changeEmployee, setChangeEmployee] = useState('')
  const [changeResponsable, setChangeResponsable] = useState('')
  const [changeActive, setChangeActive] = useState(false)
  const [changeAdminClient, setChangeAdminClient] = useState('')

  const [changeTradeName, setChangeTradeName] = useState('')
  const [additionalPhones, setAdditionalPhones] = useState([])
  const [changeAdditionalPhone, setChangeAdditionalPhone] = useState('')

  useEffect(() => {
    setChangeName(name)
    setChangeUsername(username)
    setChangeEmployee(employee)
    setChangeResponsable(responsable)
    setChangeActive(enabled === 1 ? true : false)
    setChangeTradeName(tradeName)
    setTypeContact(type)
    setChangeAdminClient(adminClient)
    setAdditionalPhones(personalPhones ? personalPhones : [])
  }, [contact])

  useEffect(() => {
    console.log()
    if (save) {
      dispatch(
        changeContact({
          name: changeName,
          username: changeUsername,
          employee: changeEmployee,
          responsable: changeResponsable,
          enabled: changeActive,
          tradeName: changeTradeName,
          adminClient: changeAdminClient,
          personalPhones: additionalPhones,
        }),
      )
    }
  }, [save])

  //additional

  // const uniqueBusinesses = useMemo(() => {
  //   if (contacts) {
  //     const uniqueBusinesses = new Set()
  //     contacts.map((contact) => {
  //       if (contact.tradeName) {
  //         uniqueBusinesses.add(contact.tradeName)
  //       }
  //     })
  //     return Array.from(uniqueBusinesses)
  //   }
  // }, [contacts])

  const turnover = useMemo(() => {
    let total = 0
    userOrders.forEach((order) => {
      total += order.items
        .map((item) => (item.marketPriceCP != '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
        .reduce((acc, curr) => acc + curr, 0)
    })
    return total.toFixed(2)
  }, [userOrders])

  const profit = useMemo(() => {
    let total = 0

    userOrders.forEach((order) => {
      const orderProfit = order.items
        .map((item) => {
          const marketPriceCP = parseFloat(item.marketPriceCP) || 0
          const ourPrice = parseFloat(item.ourPrice) || 0
          const quantity = parseFloat(item.quantity) || 1
          const IVA = parseFloat(item.IVA) || 0

          if (marketPriceCP !== 0) {
            return (marketPriceCP * quantity - ourPrice * quantity) * (1 - IVA / 100)
          }
          return 0
        })
        .reduce((acc, curr) => acc + curr, 0)

      total += orderProfit
    })

    return total.toFixed(2)
  }, [userOrders])

  const debt = useMemo(() => {
    let total = 0
    userOrders.forEach((order) => {
      parseFloat(order.paidValue)
        ? (total +=
            order.items
              .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
              .reduce((acc, curr) => acc + curr, 0) - (parseFloat(order.paidValue) || 0))
        : (total += order.items
            .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
            .reduce((acc, curr) => acc + curr, 0))
    })
    return total.toFixed(2)
  }, [userOrders])

  const addPhone = (e) => {
    e.preventDefault()

    if (!Array.isArray(additionalPhones)) {
      console.error('additionalPhones is not an array')
      return
    }

    setAdditionalPhones([...additionalPhones, { phone: changeAdditionalPhone, name: '', admin: false }])
    alert('Phone added. Save to save changes')
    setChangeAdditionalPhone('')
  }

  const changeAdditionalPhoneAdmin = (phone, admin) => {
    console.log({ phone, admin })
    console.log(additionalPhones)
    setAdditionalPhones((prev) => prev.map((item) => (item.phone === phone ? { ...item, admin: admin } : item)))
  }

  const changeAdditionalPhoneName = (phone, newName) => {
    setAdditionalPhones((prev) => prev.map((item) => (item.phone === phone ? { ...item, name: newName } : item)))
  }

  return (
    <div className='info'>
      <div className='info__left'>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Username:</p>
          {change ? (
              <TextField
                  variant='outlined'
                  size='small'
                  sx={{width: '60%'}}
                  className='info__left__item__value'
                  value={changeUsername}
                  disabled
                  onChange={(e) => setChangeUsername(e.target.value)}
              />
          ) : (
              <p className='info__left__item__value'>{username ? username : 'Not found'}</p>
          )}
        </div>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Name:</p>
          {change ? (
              <TextField
                  variant='outlined'
                  size='small'
                  sx={{width: '60%'}}
                  className='info__left__item__value'
                  value={changeName}
                  onChange={(e) => setChangeName(e.target.value)}
              />
          ) : (
              <p className='info__left__item__value'>{name ? name : 'Not found'}</p>
          )}
        </div>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Commercial Name:</p>
          {change ? (
              <TextField
                  variant='outlined'
                  size='small'
                  sx={{width: '60%'}}
                  className='info__left__item__value'
                  value={changeEmployee}
                  onChange={(e) => setChangeEmployee(e.target.value)}
              />
          ) : (
              <p className='info__left__item__value'>{employee ? employee : 'Not found'}</p>
          )}
        </div>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Responsable:</p>
          {change ? (
              <TextField
                  variant='outlined'
                  size='small'
                  sx={{width: '60%'}}
                  className='info__left__item__value'
                  value={changeResponsable}
                  onChange={(e) => setChangeResponsable(e.target.value)}
              />
          ) : (
              <p className='info__left__item__value'>{responsable ? responsable : 'Not found'}</p>
          )}
        </div>
        <div className='info__left__item' style={{position: 'relative'}}>
          <p className='info__left__item__name'>Client:</p>
          {change ? (
              typeContact === 'client' || typeContact === 'supplier' || typeContact === 'ambassador' ? (
                  <TextField
                      variant='outlined'
                      size='small'
                      sx={{width: '60%'}}
                      className='info__left__item__value'
                      value={changeTradeName}
                      onChange={(e) => setChangeTradeName(e.target.value)}
                  />
              ) : null
          ) : (
              typeContact === 'client' && <p className='info__left__item__value'>{client ? client : 'Not found'}</p>
          )}
          {typeContact != 'client' && (
              <p onClick={() => setChangeBuisnessToggle(!changeBuisnessToggle)}
                 className='info__left__item__value buisness'>
                {client ? client : 'Not found'}
              </p>
          )}
          {changeBuisnessToggle && (
              <Autocomplete
                  value={changeTradeName}
                  options={
                    tradeNames
                        ? tradeNames.map((buisness) => (buisness ? {
                          value: buisness,
                          label: buisness
                        } : {value: 'Not found', label: 'Not found'}))
                        : []
                  }
                  onChange={(e, value) => handleChangeBuisness(value?.value)}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{position: 'absolute', top: '100%', right: 0, width: '60%', backgroundColor: 'white'}}
              />

              // <div
              //   style={{
              //     position: 'absolute',
              //     top: '100%',
              //     right: 0,
              //     maxHeight: '300px',
              //     overflow: 'auto',
              //     backgroundColor: 'white',
              //     zIndex: 100,
              //     border: '1px solid black',
              //     padding: '5px',
              //     borderRadius: '5px',
              //   }}
              // >
              //   {uniqueBusinesses.map((buisness) => (
              //     <div
              //       style={{
              //         cursor: 'pointer',
              //         padding: '5px',
              //         borderRadius: '5px',
              //       }}
              //       key={buisness}
              //       className={`changeBuisness ${buisness === client ? 'active' : ''}`}
              //       onClick={() => handleChangeBuisness(buisness)}
              //     >
              //       {buisness}
              //     </div>
              //   ))}
              // </div>
          )}
        </div>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Active account:</p>
          {change ? (
              <Select
                  variant='outlined'
                  size='small'
                  // sx={{ width:  }}
                  className='info__left__item__value'
                  value={changeActive}
                  onChange={(e) => setChangeActive(e.target.value)}
              >
                <MenuItem value='true'>Active</MenuItem>
                <MenuItem value='false'>Inactive</MenuItem>
              </Select>
          ) : (
              <p className='info__left__item__value'>{enabled ? 'Active' : 'Inactive'}</p>
          )}
        </div>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Admin client:</p>
          {change ? (
              <Select
                  variant='outlined'
                  size='small'
                  // sx={{ width:  }}
                  className='info__left__item__value'
                  value={changeAdminClient || 'false'}
                  onChange={(e) => setChangeAdminClient(e.target.value)}
              >
                <MenuItem value='false'>None</MenuItem>
                <MenuItem value='TPO'>TPO</MenuItem>
                <MenuItem value='VPF'>VPF</MenuItem>
                <MenuItem value='MDL'>MDL</MenuItem>
                {/* <MenuItem value='false'>VPF</MenuItem> */}
              </Select>
          ) : (
              <p className='info__left__item__value'>{adminClient || 'Not found'}</p>
          )}
        </div>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Additional phones:</p>
          {change ? (
              <div>
                {additionalPhones &&
                    additionalPhones.map((item) => (
                        <div key={item.phone} style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                          <div>Admin?</div>
                          <Checkbox defaultChecked={item?.admin}
                                    onChange={(event, checked) => changeAdditionalPhoneAdmin(item.phone, checked)}/>
                          <TextField
                              variant='outlined'
                              size='small'
                              sx={{width: '20%'}}
                              defaultValue={item.name}
                              onChange={(e) => changeAdditionalPhoneName(item.phone, e.target.value)}
                          />
                          <div className='info__left__item__value'>{item.phone || 'Not found'}</div>

                          <Button
                              variant='outlined'
                              size='small'
                              onClick={() =>
                                  setAdditionalPhones((prev) =>
                                      prev.filter((phones) => {
                                        console.log(phones.phone)
                                        return phones.phone != item.phone
                                      }),
                                  )
                              }
                          >
                            Delete
                          </Button>
                        </div>
                    ))}
                <div style={{display: 'flex'}}>
                  <TextField
                      variant='outlined'
                      size='small'
                      sx={{width: '60%'}}
                      className='info__left__item__value'
                      value={changeAdditionalPhone}
                      onChange={(e) => setChangeAdditionalPhone(e.target.value)}
                  />
                  <Button onClick={addPhone} size='small' variant='contained' sx={{m: 1}}>
                    Add
                  </Button>
                </div>
              </div>
          ) : (
              <p
                  className='info__left__item__value'
                  style={{
                    height: '100px',
                    overflow: 'auto',
                  }}
              >
                {personalPhones ? personalPhones.map((item, index) => <p style={{display: "flex", justifyContent: "end", gap: '15px'}}
                    key={item.phone}>{ "~ " + item.phone + ' ' + item.name + ' '} <Button variant="outlined" onClick={() => handleSendBotStartWhatsapp(item)}>Send</Button>
                </p>
                ) : 'Not found'}
              </p>
          )}
        </div>
        <div className='info__left__item'>
          <p className='info__left__item__name'>Whatsapp Bot Start Send</p>
          <div>
            <Button variant="outlined" onClick={() => handleSendBotStartWhatsapp(contact)}>Send</Button>
          </div>
        </div>

      </div>
      <div className='info__right'>
        <div className='info__right__item'>
          <div className='info__right__item__info'>
            <Turnover/>
            <p>{turnover} &euro;</p>
          </div>
          <div className='info__right__item__name'>Turnover</div>
        </div>
        <div className='info__right__item'>
          <div className='info__right__item__info'>
            <ProfitIcon/>
            <p>{profit} &euro;</p>
          </div>
          <div className='info__right__item__name'>Profit</div>
        </div>
        <div className='info__right__item'>
          <div className='info__right__item__info'>
            <TiempoIcon/>
            <TextField variant='outlined' size='small' sx={{width: '50px'}} className='info__right__item__name'>
              Tiempo de picking
            </TextField>
            min
          </div>
          <div className='info__right__item__name'>Tiempo de picking</div>
        </div>
        <div className='info__right__item'>
          <div className='info__right__item__info'>
            <DebitIcon/>
            <p>{debt} &euro;</p>
          </div>
          <div className='info__right__item__name'>Debt</div>
        </div>
        <div className='info__right__minus'></div>
        <div className='info__right__line'></div>
      </div>
    </div>
  )
}
