import React, { useEffect, useMemo, useState } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Avatar, Box, Checkbox, Link, MenuItem, Modal, OutlinedInput, Select, Switch, Typography } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import {
  findProfit,
  findTotal,
} from '../../../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTableBody/OrdersChangeTableBodyItem/OrderChangeTableBodyItem'
import { ReactComponent as EurIcon } from '../../../../assets/svg/BuyerOperacion/€.svg'
import { ReactComponent as CarIcon } from '../../../../assets/svg/BuyerOperacion/mbridelivery_99588 2.svg'
import { ReactComponent as GreenCartIcon } from '../../../../assets/svg/BuyerOperacion/Calendar-Check--Streamline-Lucide.svg'
import { ReactComponent as RedCartIcon } from '../../../../assets/svg/BuyerOperacion/Calendar-X--Streamline-Lucide.svg'

import Chip from '@mui/material/Chip'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { ReactComponent as Like } from '../../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../../assets/svg/inProgress.svg'
import { ReactComponent as Production } from '../../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../../assets/svg/Congelador.svg'
import { ReactComponent as Plus } from '../../../../assets/svg/Plus.svg'
import { mainApi } from '../../../../store/services/mainApi'
import AvatarGroup from '@mui/material/AvatarGroup'
import Grid from '@mui/material/Grid'
import Item from '@mui/material/ListItem'

function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ').length < 2 ? name.split(' ')[0][0] : name.split(' ')[0][0] + '' + name.split(' ')[1][0]}`,
  }
}

const BuyerProductionListItem = ({
  editChange,
  updatePavilionHandler,
  updatePriceHandler,
  updateQuantityHandler,
  setEditChange,
  item,
  statuses,
  mobileScreen,
  rowStyle,
  checkBoxStyle,
  rowStyleMap,
  switchStyle,
  statusBackground,
  selectedItems,
  allItemsArray,
  handleTopLevelSelectAlone,
  handleAllItemsSelect,
  setSwitchChecker,
  switchChecker,
  rows,
  openRows,
  toggleRowOpen,
  handleNestedSelect,
}) => {
  const { data: orderData, isFetching, refetch } = mainApi.useGetOrdersByIdQuery(item._id)
  const [changeItems] = mainApi.useChangeItemsInOrderMutation()
  const [changeDeliveryTime] = mainApi.useChangeOrderDeliveryTimeMutation()

  const [currentDeliveryTime, setCurrentDeliveryTime] = useState(item?.deliveryTime && item?.deliveryTime.includes('PLT') ? 'PLT' : 'PLM')

  const findTotal = (marketPrice, ourPrice, IVA, quantity) => {
    const total = marketPrice * quantity

    return !isNaN(total) ? total.toFixed(2) : '0.00'
  }

  const [debounceTimeout, setDebounceTimeout] = useState(null)

  const [pavilion, setPavilion] = useState(item.items?.pavilion || [])
  const [buyerPrice, setBuyerPrice] = useState(item?.items?.marketPriceBuyer || item?.items?.quantity * item.items?.marketPriceCP)
  const [buyerQuantity, setBuyerQuantity] = useState(item?.items?.quantityBuyer || item?.items?.quantity)

  useEffect(() => {
    if (buyerQuantity === null) return

    // Очищаем предыдущий таймер, если он существует
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    // Устанавливаем новый таймер
    const timeout = setTimeout(() => {
      updateQuantityHandler(buyerQuantity, item)
    }, 1500)

    // Сохраняем таймер в состояние
    setDebounceTimeout(timeout)

    // Очищаем таймер при размонтировании или перед обновлением
    return () => clearTimeout(timeout)
  }, [buyerQuantity])

  useEffect(() => {
    if (buyerPrice === null) return

    // Очищаем предыдущий таймер, если он существует
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    // Устанавливаем новый таймер
    const timeout = setTimeout(() => {
      updatePriceHandler(buyerPrice, item)
    }, 1500)

    // Сохраняем таймер в состояние
    setDebounceTimeout(timeout)

    // Очищаем таймер при размонтировании или перед обновлением
    return () => clearTimeout(timeout)
  }, [buyerPrice])

  const findProfit = (marketPrice, ourPrice, IVA, quantity) => {
    if (isNaN(marketPrice)) {
      return '0.00'
    }

    const revenue = marketPrice * quantity
    const cost = ourPrice * quantity

    const profit = revenue - cost

    return profit.toFixed(2)
  }

  const [statusModal, setStatusModal] = useState(false)

  const [updateStatus, setUpdateStatus] = useState(statusBackground(item?.items?.statusBuyer?.name))

  // document.addEventListener("click", () => setStatusModal(false));
  const handleOpenModal = () => {
    setStatusModal(!statusModal)
  }

  const setChangeDeliveryTime = (orderData, order, value) => {
    try {
      console.log('Tis', orderData, order, value)
      changeDeliveryTime({
        ...orderData,
        deliveryTime: value == 'PLT' ? 'PLT🌙' : 'PLM☀️',
      })
        .unwrap()
        .then((res) => {
          if (!res.acknowledged) {
            alert('erorr')
          }
        })
    } catch (e) {
      console.log(e)
    }
  }

  const setChangeStatus = async (item, status) => {
    try {
      // Установить фон для статуса
      setUpdateStatus(statusBackground(status?.name))
      // Актуализировать данные перед изменением
      await refetch()
        .then(() => {
          changeItems({
            elem: {
              ...orderData,
              items: [...orderData.items.filter((el) => el._id != item.items._id), { ...item.items, status: status }],
            },
          })
        })
        .catch((error) => {
          console.error('Ошибка при изменении статуса:', error)
        })
    } catch (e) {
      console.error('Ошибка при изменении статуса:', e)
    }
  }

  return (
    <React.Fragment key={item._id + item.items._id}>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', marginBottom: '10px' } }}>
        <TableCell sx={{ ...rowStyleMap, padding: '0', textAlign: 'center', height: '40px' }}>
          <Box>
            <Checkbox
              sx={{ ...checkBoxStyle }}
              // Checked if all nested orders are selected
              checked={selectedItems.some((i) => i._id + i.items._id === item._id + item.items._id)}
              onChange={(event) => handleTopLevelSelectAlone(event, item)}
            />
          </Box>
        </TableCell>
        <TableCell align='right' sx={{ ...rowStyleMap, color: 'green', fontWeight: 'bold', padding: '0px', zIndex: '9999' }}>
          <Box>
            <div
              style={{
                width: '24px',
                height: '24px',
                borderRadius: '7px',
                background: '#fff',
                border: '2px solid #06c0b0',

                color: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                top: '1px',
                cursor: 'pointer',

                //   background: status ? '#06c0b0' : '#fff',
              }}
              onClick={(e) => {
                e.stopPropagation()
                handleOpenModal()
              }}
            >
              {updateStatus}

              <Modal open={statusModal} onClose={handleOpenModal}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    height: 300,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <div
                    className='itemStatus'
                    style={{
                      display: statusModal ? 'grid' : 'none',
                      gridTemplateColumns: '1fr 1fr',
                      width: '100%',
                      justifyContent: 'space-between',
                      height: '300px',
                      position: 'relative',
                      top: '50%',
                      left: '0',
                      transform: 'translate(0, -50%)',
                      border: 'none',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      style={{ border: '0px solid #000' }}
                      onClick={() =>
                        setChangeStatus(
                          item,
                          statuses.find((status) => status._id === '678bd4f85b9da5828a113845'),
                        )
                      }
                      className='itemStatus__item'
                    >
                      <EurIcon width={30} height={30} />
                      <span>Pagado</span>
                    </div>

                    <div
                      style={{ border: '0px solid #000' }}
                      onClick={() =>
                        setChangeStatus(
                          item,
                          statuses.find((status) => status._id === '678bd5705b9da5828a113847'),
                        )
                      }
                      className='itemStatus__item'
                    >
                      <RedCartIcon width={30} height={30} />
                      <span>Comprar</span>
                    </div>

                    <div
                      style={{ border: '0px solid #000' }}
                      onClick={() =>
                        setChangeStatus(
                          item,
                          statuses.find((status) => status._id === '678bd50f5b9da5828a113846'),
                        )
                      }
                      className='itemStatus__item'
                    >
                      <GreenCartIcon width={30} height={30} />
                      <span>Reservado</span>
                    </div>

                    <div
                      style={{ border: '0px solid #000' }}
                      onClick={() =>
                        setChangeStatus(
                          item,
                          statuses.find((status) => status._id === '678bd4425b9da5828a113844'),
                        )
                      }
                      className='itemStatus__item'
                    >
                      <CarIcon width={30} height={30} />
                      <span>Cargado</span>
                    </div>

                    <div onClick={() => setChangeStatus(item, {})} className='itemStatus__add'>
                      Delete
                      <Plus
                        width={15}
                        height={15}
                        style={{
                          position: 'relative',
                          // top: '1px',
                          left: '12px',
                        }}
                      />
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </Box>
        </TableCell>
        <TableCell sx={rowStyleMap} component='th' scope='row'>
          <Box>{!switchChecker && <img src={item.items.img} style={{ width: 'auto', height: '50px' }} />}</Box>
        </TableCell>
        <TableCell sx={rowStyleMap} component='th' scope='row'>
          <Box>{item.items.name}</Box>
        </TableCell>
        <TableCell sx={rowStyleMap} align='left'>
          {!editChange ? (
            <Box>{pavilion.join(', ')}</Box>
          ) : (
            <Box>
              <Autocomplete
                multiple
                color={'success'}
                id='tags-standard'
                options={item.items?.pavilion ? pavilion.map((option) => option) : []}
                defaultValue={item.items?.pavilion}
                freeSolo
                onChange={(e, value) => {
                  updatePavilionHandler(value, item)
                  setPavilion(value)
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const { key, ...tagProps } = getTagProps({ index })
                    return <Chip variant='outlined' label={option} key={key} {...tagProps} />
                  })
                }
                renderInput={(params) => <TextField {...params} color={'success'} variant='standard' />}
              />
            </Box>
          )}
          {/*<Link target="_blank" href={"/admin/orders/" + item._id}>
                        {item.userAcc.name + (" (" + item.tradeName || item.userAcc.tradeName + ")")}
                    </Link>*/}
        </TableCell>
        <TableCell sx={rowStyleMap} align='left'>
          {!editChange ? (
            <Box>
              {item?.items?.quantityBuyer
                ? buyerQuantity +
                  ' ' +
                  (item.items.currentUnit || item.items.unit) +
                  ' (' +
                  item?.items?.quantity +
                  ' ' +
                  (item.items.currentUnit || item.items.unit) +
                  ')'
                : item?.items?.quantity + ' ' + (item.items.currentUnit || item.items.unit)}
            </Box>
          ) : (
            <TextField
              color='success'
              value={buyerQuantity}
              label={'Quantity'}
              onChange={(e) => setBuyerQuantity(e.target.value)}
              sx={{ width: '100px' }}
              variant='standard'
              InputProps={{
                inputProps: {
                  style: { textAlign: 'right' }, // Расположение текста по правой стороне
                },
              }}
              // label='Name'
            />
          )}
        </TableCell>

        <TableCell sx={rowStyleMap} align='left'>
          <Box>
            {!editChange ? (
              item?.items?.marketPriceBuyer ? (
                parseFloat(buyerPrice).toFixed(2) + '€' + ' (' + item?.items?.quantity * item.items?.marketPriceCP + '€)'
              ) : (
                item?.items?.quantity * item.items?.marketPriceCP + '€'
              )
            ) : (
              <TextField
                color='success'
                value={buyerPrice}
                label={'Price'}
                onChange={(e) => setBuyerPrice(e.target.value)}
                sx={{ width: '100px' }}
                InputProps={{
                  inputProps: {
                    style: { textAlign: 'right' }, // Расположение текста по правой стороне
                  },
                }}
                variant='standard'
                // label='Name'
              />
            )}
          </Box>
        </TableCell>
        <TableCell sx={rowStyleMap} align='left'>
          <Box>
            {item.items?.comprador ? (
              <AvatarGroup sx={{ justifyContent: 'center' }} max={3} align='left'>
                {item.items?.comprador.map((el) => (
                  <Avatar key={el._id} {...stringAvatar(el?.name ? el.name : 'No Name')} />
                ))}
              </AvatarGroup>
            ) : (
              <Avatar align='right' sx={{ backgroundColor: 'purple' }}>
                NN
              </Avatar>
            )}
          </Box>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default BuyerProductionListItem
